import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

export const receiveTestDataApiSpecification = {
    openapi: "3.0.0",
  info: {
    title: "Test Data Reception API",
    version: "1.0.0",
    description: "API for receiving test data and updating Firestore documents accordingly."
  },
  paths: {
    "/": {
      post: {
        summary: "Receive and process test data",
        description: "Accepts test data and updates the specified Firestore document with the test result.",
        requestBody: {
          required: true,
          content: {
            "application/json": {
              schema: {
                type: "object",
                properties: {
                  accountId: { type: "string", description: "The account ID associated with the patient." },
                  patientId: { type: "string", description: "The patient's unique identifier." },
                  encounterId: { type: "string", description: "The encounter's unique identifier." },
                  testResult: { type: "string", description: "The result of the patient's test." }
                },
                required: ["accountId", "patientId", "encounterId", "testResult"]
              }
            }
          }
        },
        responses: {
          "200": { description: "Test result added successfully." },
          "400": { description: "Bad request. Account ID, Patient ID, Encounter ID, and test result are required." },
          "500": { description: "Internal Server Error. Error adding test result." }
        }
      }
    }
  }
};