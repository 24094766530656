import { useEffect } from "react";
import InputMask from "react-input-mask";

const InputTypeText = ({
  fieldItem,
  isVisible = true,
  preview = true,
  handleInputChange = () => {},
  formElementId = null,
  validationType = "None",
}) => {
  useEffect(() => {
    // Add event listener to input field to restrict input to numeric characters
    const inputElement = document.getElementById(
      formElementId ?? fieldItem?.name
    );
    if (
      inputElement &&
      (validationType === "Phone" || validationType === "Zip")
    ) {
      inputElement.addEventListener("input", (event) => {
        const { value } = event.target;
        event.target.value = value.replace(/\D/g, ""); // Remove non-numeric characters
      });
    }

    // Clean up event listener on component unmount
    return () => {
      if (inputElement) {
        inputElement.removeEventListener("input");
      }
    };
  }, [validationType, formElementId, fieldItem]);

  const isValid = (value) => {
    switch (validationType) {
      case "Alphabetic":
        return /^[a-zA-Z]+$/.test(value);
      case "AlphaNumeric":
        return /^[a-zA-Z0-9]+$/.test(value);
      case "Numeric":
        return /^[0-9]+$/.test(value);
      case "Phone":
        return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
          value
        );
      case "Email":
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      case "Zip":
        return /^\d{5,9}$/.test(value); // Zip code should be numeric with length between 5 and 9
      case "Tel":
        return /^\d{10,15}$/.test(value); // Phone number should be numeric with length between 10 and 15
      default:
        return true;
    }
  };

  const isMaxLengthValid = (value) => {
    let maxLength = 1024; // Default maximum length is 1024

    // Adjust maximum length based on validation type if needed
    switch (validationType) {
      case "Phone":
        maxLength = 16; // Maximum length for phone numbers is 16 (including country code and separators)
        break;
      case "Email":
        maxLength = 320; // Maximum length for email addresses is 320 characters (per RFC specifications)
        break;
      // Add more cases here if needed for other validation types
      default:
        break;
    }

    // Check if value is not null or undefined
    if (value !== null && value !== undefined) {
      if (maxLength && value.length > maxLength) {
        return false;
      }
    }
    return true;
  };

  return (
    <>
      {isVisible && fieldItem?.isVisible && (
        <>
          {fieldItem?.label && (
            <label>
              {fieldItem?.label}
              <span className="RequiredMark">
                {fieldItem?.required && " *"}
              </span>
            </label>
          )}
          {fieldItem?.type == "phone" ? (
            <InputMask
              id="phone"
              name={formElementId ?? fieldItem?.name}
              mask="+1 (999) 999-9999" // Change the mask pattern
              value={fieldItem.value || ""}
              placeholder={fieldItem?.placeholder}
              required={fieldItem?.required}
              onChange={(e) => {
                handleInputChange;
                if (
                  !preview &&
                  isValid(e.target.value) &&
                  isMaxLengthValid(e.target.value)
                ) {
                  handleInputChange(e);
                }
              }}
              readOnly={preview}
            />
          ) : (
            <input
              type={fieldItem?.type}
              name={formElementId ?? fieldItem?.name}
              placeholder={fieldItem?.placeholder}
              required={fieldItem?.required}
              value={fieldItem.value || ""}
              onChange={(e) => {
                if (
                  !preview &&
                  isValid(e.target.value) &&
                  isMaxLengthValid(e.target.value)
                ) {
                  handleInputChange(e);
                }
              }}
              readOnly={preview}
            />
          )}
          {!isValid(fieldItem?.value) && (
            <div className="ValidationError">
              Invalid input for {validationType} validation.
            </div>
          )}
          {!isMaxLengthValid(fieldItem?.value) && (
            <div className="ValidationError">
              Input value exceeds maximum length.
            </div>
          )}
        </>
      )}
    </>
  );
};

export default InputTypeText;
