import React, { useRef, useState, useEffect, useCallback } from "react";
import { auth } from "../../../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import PayerSelection from "../../Payers/PayerSelection";
import ProviderSelection from "../../Providers/ProviderSelection";
import HexSpinner from "../../General/Animations/Hexspinner";
import Confetti from "../../General/Animations/Confetti";
import {
  getFirestore,
  collection,
  query,
  orderBy,
  onSnapshot,
  doc,
  getDoc,
} from "firebase/firestore";
import CollapsibleSection from "../Subcomponents/CollapsibleSection";
import ServiceLineTable from "../Subcomponents/ServiceLineTable";
import { ProviderProvider } from "../../../context/ProviderContext";
import useUID from "../../General/useUID";
import HelpArticleLink from "../../Articles/HelpArticleLink";
import useEmailVerified from "../../General/useEmailVerified";
import NoSpaceInput from "../../General/NoSpaceInput";
import styles from '../Claims.css';

const db = getFirestore();

function SingleClaim({
  onSuccess,
  initialFirstName,
  initialMiddleName,
  initialLastName,
  initialDob,
  initialDos,
  initialDosTo,
  initialGender,
  initialmemberId,
  initialaddress1,
  initialaddress2,
  initialcity,
  initialstate,
  initialzip,
  initialTradingPartnerName,
  patientId,
  resubmitClaimControlNumber,
}) {
  const [firstName, setFirstName] = useState(initialFirstName || "");
  const [middleName, setMiddleName] = useState(initialMiddleName || "");
  const [lastName, setLastName] = useState(initialLastName || "");
  const [dob, setDob] = useState(initialDob || "");
  const [dos, setDos] = useState(initialDos || "");
  const [dosTo, setDosTo] = useState(initialDosTo || "");
  const [gender, setGender] = useState(initialGender || "");
  const [memberId, setmemberId] = useState(initialmemberId || "");
  const [address1, setAddress1] = useState(initialaddress1 || "");
  const [address2, setAddress2] = useState(initialaddress2 || "");
  const [city, setCity] = useState(initialcity || "");
  const [state, setState] = useState(initialstate?.toUpperCase() || "");
  const [zip, setzip] = useState(initialzip || "");
  const [priorAuthorizationNumber, setpriorAuthorizationNumber] = useState("");
  const [patientData, setPatientData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [payers] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [payerError, setPayerError] = useState("");
  const [hasRemainingRequests, setHasRemainingRequests] = useState(true);
  const [activeSection, setActiveSection] = useState(1);
  const [totalCharges, setTotalCharges] = useState(0);
  const [isTestMode, setIsTestMode] = useState(false);
  const [selectedBillingCodes, setSelectedBillingCodes] = useState([]);
  const [selectedServiceProviders, setSelectedServiceProviders] = useState([]);
  const [selectedRenderingProviders, setSelectedRenderingProviders] = useState(
    []
  );
  const [selectedBillingProviders, setSelectedBillingProviders] = useState([]);
  const [selectedReferringProviders, setSelectedReferringProviders] = useState([]);
  const [showReferringProviderButton, setShowReferringProviderButton] = useState(true);  
  const [selectedPayers, setSelectedPayers] = useState(null);
  const [showDependentPopup, setShowDependentPopup] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errors, setErrors] = useState({});
  const [errors2, setErrors2] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const states = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
    'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
    'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
    'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
  ];
  const myRef = useRef(null);
  const [uid, subUserUID] = useUID();
  const isEmailVerified = useEmailVerified();

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Reset errors on resubmission
    setErrorMessage("");
    setSuccessMessage("");
    setFormErrors({});
    setErrors2({});

    if (!isEmailVerified) {
      alert("Please verify your email before submitting claims.");
      return;
    }

    if (!hasRemainingRequests) {
      setErrorMessage(
        "You have reached the limit of 25 API requests for the free trial."
      );
      return;
    }

    const currentUser = auth.currentUser;
    const uid = currentUser ? currentUser.uid : null;

    const formatDate = (date) => {
      const utcDate = Date.parse(date);
      const estOffset = 5 * 60 * 60 * 1000; // Eastern Standard Time (EST) offset from UTC in milliseconds
      const estDate = new Date(utcDate + estOffset);
      const year = estDate.getFullYear();
      const month = ("0" + (estDate.getMonth() + 1)).slice(-2);
      const day = ("0" + estDate.getDate()).slice(-2);

      return `${year}${month}${day}`;
    };

    setIsLoading(true);
    try {
      const formattedDob = dob ? formatDate(dob) : "";
      const formattedDos = dos ? formatDate(dos) : "";
      const formattedDosTo = dosTo ? formatDate(dosTo) : "";

      const claimData = {
        firstName,
        middleName,
        lastName,
        dob: formattedDob,
        gender,
        dos: formattedDos,
        dosTo: formattedDosTo,
        memberId,
        address1,
        address2,
        city,
        state,
        zip,
        selectedPayers: selectedPayers,
        uid,
        selectedBillingCodes,
        selectedServiceProviders,
        selectedRenderingProviders,
        selectedBillingProviders,
        selectedReferringProviders,
        totalCharges,
        priorAuthorizationNumber,
        patientId,
        resubmitClaimControlNumber,
        usageIndicator: isTestMode ? 'T' : 'P'  // 'P' for production, 'T' for test
      };

      const functions = getFunctions();
      const claimsSingleAPI = httpsCallable(functions, "claimsSingleAPI");
      const result = await claimsSingleAPI({ claimData });

      if (result && result.data) {
        if (result.data.success) {
          // Show a generic success message
          setSuccessMessage("Your claim submission was successful!"); // Set success message
        } else if (result.data.errors) {
          // Handle an array of error messages
          const combinedErrorMessages = result.data.errors.join("; ");
          setErrorMessage(combinedErrorMessages);
        } else if (result.data.error) {
          // Handle a single error message
          setErrorMessage(result.data.error);
        } else {
          // Handle an unexpected error format
          setErrorMessage("An unexpected error occurred.");
        }
      } else {
        setErrorMessage("No response data received from the server.");
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error adding document: ", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {}, [patientData]);

  const handlePayerSelect = (payer) => {
    setSelectedPayers(payer);
  };


  useEffect(() => {
    const fetchAccountTierAndCheckRemainingRequests = async () => {
      const uid = auth.currentUser.uid;
      const userDocRef = doc(db, "users", uid);
      const userDocSnap = await getDoc(userDocRef);

      const AccountTier = userDocSnap.data().AccountTier || "";

      const requestsRef = collection(db, "users", uid, "API");
      const q = query(requestsRef, orderBy("timestamp", "desc"));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let total = 0;
        querySnapshot.forEach((doc) => {
          const requestData = doc.data();

          if (requestData.APIRequests) {
            const apiRequestsCount = parseInt(requestData.APIRequests, 10);
            total += apiRequestsCount;
          }
        });

        if (AccountTier === "Freebie" && total >= 25) {
          setHasRemainingRequests(false);
        } else {
          setHasRemainingRequests(true);
        }
      });

      return () => unsubscribe();
    };

    if (auth.currentUser) {
      fetchAccountTierAndCheckRemainingRequests();
    }
  }, [auth.currentUser]);

  const handleBillingProviderSelection = (data) => {
    setSelectedBillingProviders(data);
  };

  const handleUnifiedProviderSelection = (data) => {
    setSelectedServiceProviders(data);
    setSelectedRenderingProviders(data);
    // setselectedReferringProviders(providerData);
  };

  const handleReferringProviderSelection = (data) => {
    setSelectedReferringProviders(data);
  };

  const handleAddReferringProvider = () => {
    setShowReferringProviderButton(false);
  };

  const handleBillingCodesSelect = (selectedCodes) => {
    setSelectedBillingCodes(selectedCodes);
  };

  const handleTotalChargesChange = (charges) => {
    setTotalCharges(charges);
  };

  const canProceedToNextSection = () => {
    const isProviderSelected = (provider) => {
      const isSelected = provider && Object.keys(provider).length > 0;
      return isSelected;
    };

    const areAllProvidersSelected =
      isProviderSelected(selectedBillingProviders) &&
      isProviderSelected(selectedServiceProviders) &&
      isProviderSelected(selectedRenderingProviders) 
    return areAllProvidersSelected;
  };

  function validateForm() {
    let errors = {};

    if (firstName.trim() === "") errors.firstName = "First name is required";
    if (lastName.trim() === "") errors.lastName = "Last name is required";
    if (dob.trim() === "") errors.dob = "Date of birth is required";
    if (memberId.trim() === "") errors.memberId = "Member ID is required";
    if (gender.trim() === "") errors.gender = "Gender is required";
    if (address1.trim() === "") errors.address1 = "Address line 1 is required";
    if (city.trim() === "") errors.city = "City is required";
    if (state.trim() === "") errors.state = "State is required";
    if (zip.trim() === "") {
        errors.zip = "ZIP code is required";
    } else {
        if (process.env.NODE_ENV === "development" && !/^\d{5}$/.test(zip)) {
            errors.zip = "ZIP should be exactly 5 digits in development mode.";
        } else if (
            process.env.NODE_ENV !== "development" &&
            !/^\d{5}$/.test(zip)
        ) {
            errors.zip = "ZIP should be exactly 5 digits in production mode.";
        }
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Returns true if no errors.
}


  useEffect(() => {
    if (activeSection === 3) {
      // Change '3' to the section number you want to scroll to
      myRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [activeSection]);


  const validateSection2 = () => {
    let sectionErrors = {};
    if (!selectedPayers) sectionErrors.payer = "Payer is required";
    if (selectedBillingCodes.length === 0)
      sectionErrors.billingCode = "Billing code is required";
    if (!dos.trim()) sectionErrors.dos = "Date of Service is required";
    setErrors2(sectionErrors);
    return Object.keys(sectionErrors).length === 0;
  };

  const validateInitialValues = useCallback(() => {
    // Check each field to ensure it's provided and not just an empty string
    return (
      initialFirstName &&
      initialFirstName.trim() !== "" &&
      initialLastName &&
      initialLastName.trim() !== "" &&
      initialDob &&
      initialDob.trim() !== "" &&
      initialGender &&
      initialGender.trim() !== "" &&
      initialmemberId &&
      initialmemberId.trim() !== "" &&
      initialaddress1 &&
      initialaddress1.trim() !== "" &&
      initialcity &&
      initialcity.trim() !== "" &&
      initialstate &&
      initialstate.trim() !== "" &&
      initialzip &&
      initialzip.trim() !== ""
    );
  }, [
    initialFirstName,
    initialLastName,
    initialDob,
    initialGender,
    initialmemberId,
    initialaddress1,
    initialcity,
    initialstate,
    initialzip,
  ]);

  useEffect(() => {
    // Run the validation when the component mounts
    if (validateInitialValues()) {
      setActiveSection(2); // Move to step 2 if validation passes
    }
  }, [validateInitialValues]);

  // Function to set Date of Service to today's date
  const setDosToYesterday = () => {
    const today = new Date();
    const yesterday = new Date(today.setDate(today.getDate() - 1)); // Subtract one day
    const formattedYesterday = yesterday.toISOString().split("T")[0]; // Formats the date to 'yyyy-mm-dd'
    setDos(formattedYesterday);
};

  return (
    <div className="Claims-inner-content">
    <div className="checkout">
      <div className="centerHeader">
        <p className="subheader-container">Please follow the steps below to send a single claim.</p>
      </div>
      <div className={styles.singleClaim}>
        <CollapsibleSection
          title={`Patient`}
          number={1}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          style={{ paddingLeft: 0 }}
        >
          <form>
            <div className="input-group-row">
              <div className="input-field">
                <label htmlFor="firstName">First Name:</label>
                <NoSpaceInput
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
                {formErrors.subfirstName && (
                  <span className="error-text">{formErrors.firstName}</span>
                )}
              </div>

              <div className="input-field">
                <label htmlFor="lastName">Middle Name:</label>
                <NoSpaceInput
                  type="text"
                  value={middleName}
                  onChange={(e) => setMiddleName(e.target.value)}
                  required
                />
                {formErrors.sublastName && (
                  <span className="error-text">{formErrors.middleName}</span>
                )}
              </div>

              <div className="input-field">
                <label htmlFor="lastName">Last Name:</label>
                <NoSpaceInput
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
                {formErrors.lastName && (
                  <span className="error-text">{formErrors.lastName}</span>
                )}
              </div>
            </div>

          <div className="input-group-row">
            <div className="input-field">
                <label htmlFor="dob">Date of Birth:</label>
                <input
                  type="date"
                  id="dob"
                  placeholder="Date of Birth"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                  required
                />
              </div>

            <div className="gender-buttons">
            <button
              id="genderMale"
              type="button"
              className={`gender-button ${gender === "M" ? "selected" : ""}`}
              onClick={() => setGender("M")}
            >
              M
            </button>
            <button
              id="genderFemale"
              type="button"
              className={`gender-button ${gender === "F" ? "selected" : ""}`}
              onClick={() => setGender("F")}
            >
              F
            </button>
          </div>
          </div>

            {formErrors.dob && (
              <span className="error-text">{formErrors.dob}</span>
            )}
            {formErrors.gender && (
              <span className="error-text">{formErrors.gender}</span>
            )}

          <div className="input-group-row">
            <div className="input-field">
              <label htmlFor="memberid">Member ID</label>
              <div className={styles.memberid}>
                <NoSpaceInput
                  className={styles.memberid}
                  type="text"
                  value={memberId}
                  onChange={(e) => {
                    const sanitizedValue = e.target.value.replace(
                      /[^a-zA-Z0-9]/g,
                      ""
                    ); // Remove anything that's not a letter or a number
                    setmemberId(sanitizedValue);
                  }}
                  required
                />
              </div>
              {formErrors.memberId && (
                <span className="error-text">{formErrors.memberId}</span>
              )}
            </div>
            </div>

            <div className="input-group-row">
              <div className="input-field">
                <label htmlFor="address1">Address line 1</label>
                <input
                  type="text"
                  value={address1}
                  onChange={(e) => setAddress1(e.target.value)}
                  required
                />
                {formErrors.address1 && (
                  <span className="error-text">{formErrors.address1}</span>
                )}
              </div>

              <div className="input-field">
                <label htmlFor="address2">Address line 2 (optional)</label>
                <input
                  type="text"
                  value={address2}
                  onChange={(e) => setAddress2(e.target.value)}
                />
              </div>
            </div>

            <div className="input-group-row">
              <div className="input-field">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                />
                {formErrors.city && (
                  <span className="error-text">{formErrors.city}</span>
                )}
              </div>

              <div className="input-field">
                <label htmlFor="state">State</label>
                <select
                  id="state"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                >
                  <option value="">Select a State</option>{" "}
                  {/* Default option */}
                  {states.map((s) => (
                    <option key={s} value={s}>
                      {s}
                    </option>
                  ))}
                </select>
                {formErrors.state && (
                  <span className="error-text">{formErrors.state}</span>
                )}
              </div>

              <div className="input-field">
                <label htmlFor="zip">Zip</label>
                <input
                    type="text"
                    placeholder="ZIP Code"
                    value={zip}
                    maxLength={process.env.NODE_ENV === "development" ? 5 : 5}
                    className="zipInput"
                    onChange={(e) => {
                        const input = e.target.value;
                        if (input.match(/^\d*$/)) {
                            setzip(input);
                        }
                    }}
                    required
                />
                {formErrors.zip && (
                    <span className="error-text">{formErrors.zip}</span>
                )}
            </div>

            </div>
          </form>
          <button
            type="button"
            onClick={() => {
              if (validateForm()) {
                setActiveSection((prevSection) => prevSection + 1);
              }
            }}
          >
            Next
          </button>
        </CollapsibleSection>

        <CollapsibleSection
          title={`Payer`}
          number={2}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          style={{ paddingLeft: 0 }}
        >
          <PayerSelection
            onSelect={handlePayerSelect}
            required
            TradingPartnerName={initialTradingPartnerName}
            mode={"claims"}
          />
          <div
            style={{
              height: "1px",
              backgroundColor: "black",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          ></div>

          <h3>Date of Service:</h3>
          <input
            type="date"
            id="dos"
            placeholder="Date of Service"
            value={dos}
            onChange={(e) => setDos(e.target.value)}
            required
          />
          <button
            type="button"
            onClick={setDosToYesterday}
            className={styles.setTodayButton}
          >
            Set to Yesterday
          </button>

          <h3>Date of Service End (optional):</h3>
          <input
            type="date"
            id="dosTo"
            placeholder="Date of Service End"
            value={dosTo}
            onChange={(e) => setDosTo(e.target.value)}
            required
          />

          <div
            style={{
              height: "1px",
              backgroundColor: "black",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          ></div>

          <ServiceLineTable
            onBillingCodesSelect={handleBillingCodesSelect}
            onTotalChargesChange={handleTotalChargesChange}
          />

          <div className="input-group-row">
            <div className="input-field">
              <label htmlFor="dob">
                Prior Authorization Number (optional):
              </label>
              <input
                type="text"
                value={priorAuthorizationNumber}
                onChange={(e) => setpriorAuthorizationNumber(e.target.value)}
              />
            </div>
          </div>

          <div className="errors2Section">
            {errors2.payer && <div className="error-text">{errors2.payer}</div>}
            {errors2.billingCode && (
              <div className="error-text">{errors2.billingCode}</div>
            )}
            {errors2.dos && <div className="error-text">{errors2.dos}</div>}
          </div>

          <button
            type="button"
            onClick={() => {
              if (validateSection2()) {
                setActiveSection((prevSection) => prevSection + 1);
              }
            }}
          >
            Next
          </button>
        </CollapsibleSection>

        <CollapsibleSection
          ref={myRef}
          title={`Provider`}
          number={3}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          style={{ paddingLeft: 0 }}
        >
          <ProviderProvider uid={uid}>
            <h3>a. Select a Billing Provider</h3>
            <h4>
              The important one. Add medicare number, medicaid number, and CLIA
              here.
            </h4>
            <ProviderSelection
              type="billing"
              onProviderSelect={handleBillingProviderSelection}
            />
            <h3>b. Select a Service Provider</h3>
            <h4>Servicing and rendering provider.</h4>
            <ProviderSelection
              type="unified"
              onProviderSelect={handleUnifiedProviderSelection}
            />
          {!showReferringProviderButton && (              <>
                <h3>c. Select a Refering Provider</h3>
                  <h4>Servicing and rendering provider.</h4>
                  <ProviderSelection
                    type="refering"
                    onProviderSelect={handleReferringProviderSelection}
                  />
            </>
          )}

            {showReferringProviderButton && (
              <button type="button" onClick={handleAddReferringProvider}>
                Add Referring Provider
              </button>
            )}
            <div className="help-article-wide">
              <HelpArticleLink
                article={{
                  title: "Adding Providers",
                  link: "https://popularishealth.com/article/Adding-Providers",
                }}
              />
            </div>

            <div className={styles.nextButtonContainer}>
              <button
                type="button"
                disabled={!canProceedToNextSection()}
                onClick={() => {
                  setActiveSection(activeSection + 1);
                }}
              >
                Next
              </button>
              {!canProceedToNextSection() && (
                <span className="error-text">Please select all providers</span>
              )}
            </div>
          </ProviderProvider>
        </CollapsibleSection>

        <CollapsibleSection
          title="Review"
          number={4}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          style={{ paddingLeft: 0 }}
        >
          <div className={styles.reviewSection}>
            <h2>Review Claim Details</h2>
            <div className={styles.reviewItem}>
              <span className={styles.lightText}>Name:</span>{" "}
              <strong>
                {firstName} {middleName} {lastName}
              </strong>
            </div>
            <div className={styles.reviewItem}>
              <span className={styles.lightText}>Gender:</span>{" "}
              <strong>{gender}</strong>
            </div>
            <div className={styles.reviewItem}>
              <span className={styles.lightText}>Date of Birth:</span>{" "}
              <strong>{dob}</strong>
            </div>
            <div className={styles.reviewItem}>
              <span className={styles.lightText}>Address:</span>{" "}
              <strong>
                {address1}{address2}, {city}, {state}, {zip}
              </strong>
            </div>
            <div className={styles.reviewItem}>
              <span className={styles.lightText}>Member ID:</span>{" "}
              <strong>{memberId}</strong>
            </div>
            <div className={styles.reviewItem}>
              <span className={styles.lightText}>Payer:</span>{" "}
              <strong>
                {selectedPayers ? selectedPayers.name : "Not selected"}
              </strong>
            </div>
            <div className={styles.reviewItem}>
                  <span className={styles.lightText}>Billing Provider:</span>
                  <strong>
                      {selectedBillingProviders
                          ? `${selectedBillingProviders.firstName} ${selectedBillingProviders.lastName} | NPI: ${selectedBillingProviders.npi}`
                          : "No provider selected"}
                  </strong>
              </div>
              <div className={styles.reviewItem}>
                  <span className={styles.lightText}>Service Provider:</span>
                  <strong>
                      {selectedServiceProviders
                          ? `${selectedServiceProviders.firstName} ${selectedServiceProviders.lastName} | NPI: ${selectedServiceProviders.npi}`
                          : "No provider selected"}
                  </strong>
              </div>
            <div className={styles.reviewItem}>
              <span className={styles.lightText}>Diagnosis Codes: </span>
              <strong>
                {selectedBillingCodes
                  .map((code) => code.diagnosisCode.join(", "))
                  .join("; ")}
              </strong>
            </div>
            <div className={styles.reviewSection}>
              <h4>Service Lines</h4>
              <table className={styles.serviceLinesTable}>
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Name</th>
                    <th>Diagnosis Code</th>
                    <th>Amount</th>
                    <th>Date of Service</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedBillingCodes.map((code, index) => (
                    <tr key={index}>
                      <td>{code.code}</td>
                      <td>{code.name}</td>
                      <td>{code.diagnosisCode.join(", ")}</td>
                      <td>${code.billAmount.toFixed(2)}</td>
                      <td>{dos}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className={styles.searchButtonContainer}>
              {isLoading ? (
                <HexSpinner />
              ) : (
                <>
                          <div>
                          <button type="button" onClick={handleSubmit}>
                            Submit Claims
                          </button>
                          </div>
                          <div className="switchTestModeContainer">
                              <label className="switch">
                              <input type="checkbox" checked={isTestMode} onChange={() => setIsTestMode(!isTestMode)} />
                              <span className="slider round"></span>
                            </label>
                            <p>{isTestMode ? 'Test Mode is ON' : 'Test Mode is OFF'}</p>
                        </div>
                        </>
              )}
          </div>
        </CollapsibleSection>
      </div>

      {payerError && (
        <div className={styles.payerErrorMessage}>{payerError}</div>
      )}
      {errorMessage && (
        <div className={styles.payerErrorMessage}>{errorMessage}</div>
      )}

      {successMessage && (
        <>
          <div className="alert alert-success">
            <h3>{successMessage}</h3>
          </div>
          <Confetti />
        </>
      )}
    </div>
  </div>
  );
}

export default SingleClaim;
