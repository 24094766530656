import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../subcomponents/Footer';
import Header from '../subcomponents/Header';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import { encountersApiSpecification } from '../API/EncountersAPISwagger'; // Adjust path as needed
import { receiveTestDataApiSpecification } from '../API/ReceiveTestDataAPISwagger'; // Adjust path as needed

const TryAPI = () => {

    const mergeApiSpecifications = (spec1, spec2) => {
        // Assuming both specs have the same components/securitySchemes for simplicity
        return {
            openapi: "3.0.0",
            info: {
                title: "Combined API Documentation",
                version: "1.0.0",
                description: "Combined documentation for Encounters API and Test Data Reception API."
            },
            paths: { ...spec1.paths, ...spec2.paths },
            components: spec1.components, // Or dynamically merge if different
            security: spec1.security // Assuming the same for both specs
        };
    };

    const combinedApiSpecification = mergeApiSpecifications(encountersApiSpecification, receiveTestDataApiSpecification);

    return (
        <div className="API-page">
             <Helmet>
                <title>API</title>
                <meta name="description" content="API for Interoperability." />
                <meta name="keywords" content="Healthcare Billing, Clinic Management, Telehealth, Insurance Eligibility, medical AI, Patient management, patient electronic intake, Medical Software, Patient Eligibility, Batch Claims, AI in Healthcare" />
                <meta property="og:title" content="API" />
                <meta property="og:description" content="API for Interoperability." />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/popularishealth.appspot.com/o/Popularis_logo_single.png?alt=media&token=e079bdf2-360b-42da-9ef2-1cdbdeb474cf" />
            </Helmet>
            <Header />
            <div>
                <main>
                <section className="websiteblock">
                    <div className='AI-header-text'>
                    <h1 style={{marginBottom: "1rem"}}>Integrate with us.</h1>
                       <div className='AI-subheader-text'>
                        <h4>We are currently developing new APIs for Popularis. Currently, we offer encounters and lab test results APIs. Please feel free to reach out to our staff to request a new API.</h4>
                        </div>
                    </div>
                <div className="swagger-container">
                    <SwaggerUI spec={combinedApiSpecification} />
                </div>
            </section>
        </main>
     </div>
    <Footer />
    </div>
    );
};


export default TryAPI;
