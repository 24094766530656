import React, { useEffect, useState, useCallback, useRef } from 'react';
import { collection, query, getDocs, doc, getDoc, orderBy, startAfter, limit, where } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faUserPlus, faUpload, faHospitalUser, faFileMedical, 
    faStickyNote, faVideo, faFileAlt, faFlag , faStethoscope, faStaffSnake
} from '@fortawesome/free-solid-svg-icons';
import useUID from '../General/useUID';
import { db } from "../../firebase";
import Topbar from '../General/Topbar';
import Modal from 'react-modal';
import HelpArticleLink from "../Articles/HelpArticleLink";
import { getFunctions, httpsCallable } from "firebase/functions";
import HexagonSpinner from "../General/Animations/Hexspinner";
import ClinicNameFetcher from './ClinicNameFetcher';

const functions = getFunctions();

function Activity() {
  const ITEMS_PER_PAGE = 25;

  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [selectedActivityTypes, setSelectedActivityTypes] = useState({
    addPatient: true,
    intakePatientAdd: true,
    batchPatients: true,
    clinicCreated: true,
    medicalHistory: true,
    newNote: true,
    newEncounter: true,
    fileAdded: true,
    zoomScheduled: true,
    flagAdded: true,
    addEncounter: true,
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [uid] = useUID();
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMoreActivities, setHasMoreActivities] = useState(true);
  const navigate = useNavigate();
  const observer = useRef();

  const fetchActivities = useCallback(async (reset = false) => {
    if (!uid) return;
    if (reset) {
      setLoading(true);
      setActivities([]);
      setLastVisible(null);
      setHasMoreActivities(true);
    } else {
      setIsFetchingMore(true);
    }

    try {
      let activityCollection = collection(db, `users/${uid}/activityLogs`);
      let q = query(activityCollection, orderBy('timestamp', 'desc'), limit(ITEMS_PER_PAGE));
      
      if (startDate) {
        q = query(q, where('timestamp', '>=', new Date(startDate).getTime()));
      }
      if (endDate) {
        q = query(q, where('timestamp', '<=', new Date(endDate).getTime()));
      }
      
      if (lastVisible && !reset) {
        q = query(q, startAfter(lastVisible));
      }

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const filteredActivities = await Promise.all(
          querySnapshot.docs.map(async (activityDoc) => {
            const data = activityDoc.data();
            if (!selectedActivityTypes[data.activityType]) return null;

            let patientName = '';
            if (data.patientId) {
              const patientRef = doc(db, `patients/${uid}/patientData`, data.patientId);
              const patientDoc = await getDoc(patientRef);
              if (patientDoc.exists()) {
                const decryptFunction = httpsCallable(functions, "decrypt");
                const encryptedpatientData = patientDoc.data().patient;
                const response = await decryptFunction(encryptedpatientData);
                const patientData = response.data.patient;
                patientName = `${patientData.firstName} ${patientData.lastName}`;
              }
            }
            return { id: activityDoc.id, ...data, patientName };
          })
        );

        const newActivities = filteredActivities.filter((activity) => activity !== null);

        setActivities((prevActivities) => reset ? newActivities : [...prevActivities, ...newActivities]);
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]); // Set the last visible document
        setHasMoreActivities(querySnapshot.docs.length === ITEMS_PER_PAGE);
      } else {
        setHasMoreActivities(false);
      }
    } catch (error) {
      console.error("Error fetching activities:", error);
    } finally {
      if (reset) {
        setLoading(false);
      } else {
        setIsFetchingMore(false);
      }
    }
  }, [uid, lastVisible, startDate, endDate, selectedActivityTypes]);

  useEffect(() => {
    fetchActivities(true);
  }, [uid, startDate, endDate, selectedActivityTypes]);

  const lastActivityRef = useCallback((node) => {
    if (loading || isFetchingMore) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMoreActivities) {
        fetchActivities();
      }
    });

    if (node) observer.current.observe(node);
  }, [loading, isFetchingMore, hasMoreActivities, fetchActivities]);

  const renderActivity = ({ id, timestamp, activity, activityType, patientId, patientName }, index) => {
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    const dateString = date.toLocaleString();

    const iconMap = {
      addPatient: faUserPlus,
      intakePatientAdd: faHospitalUser,
      batchPatients: faUpload,
      clinicCreated: faHospitalUser,
      medicalHistory: faFileMedical,
      newNote: faStickyNote,
      newEncounter: faStaffSnake,
      addEncounter: faStaffSnake,
      fileAdded: faFileAlt,
      zoomScheduled: faVideo,
      flagAdded: faFlag,
    };

    const IconComponent = iconMap[activityType] || faFlag;

    return (
      <div key={id} ref={activities.length === index + 1 ? lastActivityRef : null} className="ActivityCard">
        <div className="ActivityTimelinePoint"></div>
        <div className="ActivityDetails">
          <div className="ActivityDate">{dateString}</div>
          <div className="ActivityIcon">
            <FontAwesomeIcon icon={IconComponent} size="lg" style={{ marginRight: '1rem' }} />
          </div>
          <div className="ActivityInfo">
            {patientName && <span>{patientName} - </span>}
            <span>{activity}</span>
            {patientId && (
              <button className='activityButton' onClick={() => navigate(`/patients/${patientId}`)}>
                View {activityType === 'medicalHistory' ? 'History' : 'Patient'}
              </button>
            )}          
            <ClinicNameFetcher patientId={patientId}/>
          </div>
        </div>
      </div>
    );
  };

  const activityTypeLabels = {
    addPatient: 'Add Patient',
    intakePatientAdd: 'Intake Patient',
    batchPatients: 'Batch Patients Upload',
    clinicCreated: 'Clinic Created',
    medicalHistory: 'Medical History Added',
    newNote: 'New Note Created',
    newEncounter: 'New Encounter',
    fileAdded: 'File Added',
    zoomScheduled: 'Zoom Meeting Scheduled',
    flagAdded: 'Flag Added',
    addEncounter: 'Add Encounter',
  };

  const applyFiltersAndCloseModal = () => {
    setIsFilterModalOpen(false);
    fetchActivities(true);
  };

  const clearFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedActivityTypes({
      addPatient: true,
      intakePatientAdd: true,
      batchPatients: true,
      clinicCreated: true,
      medicalHistory: true,
      newNote: true,
      newEncounter: true,
      fileAdded: true,
      zoomScheduled: true,
      flagAdded: true,
      addEncounter: true,
    });
    fetchActivities(true);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSelectedActivityTypes(prev => ({
      ...prev,
      [name]: checked,
    }));
    fetchActivities(true);
  };

  const handleDateChange = (event, type) => {
    const value = event.target.value;
    if (type === 'startDate') {
      setStartDate(value);
    } else {
      setEndDate(value);
    }
    fetchActivities(true);
  };

  const renderFilterModal = () => {
    return (
      <Modal
        isOpen={isFilterModalOpen}
        onRequestClose={() => setIsFilterModalOpen(false)}
        contentLabel="Filter Activities"
        className="confirmModal"
      >
          <div className="closeButtonContainer">
              <button onClick={() => setIsFilterModalOpen(false)} className="filesCloseButton">
                X
              </button>
              <p className="closeBarNav">Filter Activities</p>
            </div>
        <div>

        </div>
        <div style={{ gap: "10px", marginTop: '20px' }}>
          <button onClick={clearFilters} className='clearFiltersButton'>Clear Filters</button>
          <button onClick={applyFiltersAndCloseModal} className='applyFiltersButton'>Apply Filters</button>
        </div>
        <div className="date-filters">
          <label>
            Start Date:
            <input
              type="date"
              value={startDate ? startDate : ''}
              onChange={(e) => handleDateChange(e, 'startDate')}
              className="date-input"
            />
          </label>
          <label>
            End Date:
            <input
              type="date"
              value={endDate ? endDate : ''}
              onChange={(e) => handleDateChange(e, 'endDate')}
              className="date-input"
            />
          </label>
        </div>
        <div className="activity-type-filters">
          {Object.entries(selectedActivityTypes)
            .filter(([type]) => activityTypeLabels[type])
            .map(([type, isSelected]) => (
              <div key={type}>
                <label>
                  <input
                    type="checkbox"
                    className='custom-checkbox'
                    name={type}
                    checked={isSelected}
                    onChange={handleCheckboxChange}
                  />
                  {activityTypeLabels[type]}
                </label>
              </div>
          ))}
        </div>
      </Modal>
    );
  };

  return (
    <div>
      <Topbar />
      {renderFilterModal()}
      <div style={{ marginLeft: "10vw", marginTop: "5rem"}}>
      <div className="closeButtonContainer">
              <button onClick={() => navigate('/patients')} className="filesCloseButton">
                X
              </button>
              <p className="closeBarNav">Your Activity</p>
            </div>

        
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <button className='Activities-bottons' onClick={() => setIsFilterModalOpen(true)}>Filter Activities</button>
          <button className='clearFiltersButton' onClick={clearFilters}>Clear Filters</button>
        </div>
      </div>
      <div className='ActivityContainer'>
        {loading && <HexagonSpinner />}
        {!loading && activities.length > 0 && activities.map(renderActivity)}
        {isFetchingMore && <HexagonSpinner />}
        {!loading && activities.length === 0 && <p>No activities found for the selected filters.</p>}
        <HelpArticleLink 
          article={{ 
            title: 'Activities', 
            link: 'https://popularishealth.com/article/Activities' 
          }} 
        />
      </div>
    </div>
  );
}

export default Activity;
