import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import Modal from "react-modal";
import { db } from "../../../firebase";
import HexagonSpinner from "../../General/Animations/Hexspinner";
import useUID from "../../General/useUID";
import BatchClaimsJSON from "./BatchClaimsJSON"; // Import the BatchClaimsJSON component

const BillableEncounters = ({ onClose }) => {
  const [loading, setLoading] = useState(true);
  const [encounters, setEncounters] = useState([]);
  const [filteredEncounters, setFilteredEncounters] = useState([]); // State for filtered encounters
  const [showBatchClaimsJSON, setShowBatchClaimsJSON] = useState(false); // State to manage overlay visibility
  const [showCheckboxes, setShowCheckboxes] = useState(false); // State to show checkboxes
  const [selectedEncounters, setSelectedEncounters] = useState([]); // State to manage selected encounters
  const [selectedPatientsData, setSelectedPatientsData] = useState([]); // State to manage selected patients' data
  const [errors, setErrors] = useState({}); // State to manage validation errors
  const [uid] = useUID();
  const [selectAll, setSelectAll] = useState(false); // State for Select All functionality
  const [loadedCount, setLoadedCount] = useState(0); // State to manage number of loaded encounters
  const [showFilterModal, setShowFilterModal] = useState(false); // State to manage filter modal visibility
  const [fromDate, setFromDate] = useState(""); // State for start date filter
  const [toDate, setToDate] = useState(""); // State for end date filter
  const [filterBilled, setFilterBilled] = useState(""); // State for billed filter
  const [filterDuplicates, setFilterDuplicates] = useState(false); // State for duplicates filter

  const functions = getFunctions();
  const batchDecryptEncounters = httpsCallable(functions, 'batchDecryptEncounters');

  function formatDate(timestamp) {
    if (timestamp) {
      let date;
      if (timestamp instanceof Date) {
        date = timestamp;
      } else if (typeof timestamp === 'number') {
        date = new Date(timestamp);
      } else {
        date = new Date(timestamp);
      }
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    }
    return "Unknown date";
  }
  

  const loadMoreEncounters = async () => {
    if (!uid) return;
  
    setLoading(true);
  
    const patientDataCollectionRef = collection(db, `patients/${uid}/patientData`);
    const patientDocs = await getDocs(patientDataCollectionRef);
  
    const patients = [];
    const encounters = [];
  
    // Collect patients and encounters with IDs
    await Promise.all(patientDocs.docs.map(async (patientDoc) => {
      const patientData = patientDoc.data();
      patients.push({
        id: patientDoc.id, // Include patient ID
        ciphertext: patientData.patient.ciphertext,
        iv: patientData.patient.iv,
      });
  
      const encountersRef = collection(patientDoc.ref, "encounters");
      const encounterDocs = await getDocs(encountersRef);
  
      encounterDocs.docs.forEach((encounterDoc) => {
        const encounterData = encounterDoc.data();
        encounters.push({
          id: encounterDoc.id, // Include encounter ID
          patientId: patientDoc.id, // Include patient ID
          ciphertext: encounterData.ciphertext,
          iv: encounterData.iv,
          timestamp: encounterData.timestamp ? encounterData.timestamp.toMillis() : null, // Convert to milliseconds
          BillingStatus: encounterData.BillingStatus,
        });
      });
    }));
  
    // Call your cloud function
    const decryptedResponse = await batchDecryptEncounters({ patients, encounters });
    const decryptedPatients = decryptedResponse.data.decryptedPatients;
    const decryptedEncounters = decryptedResponse.data.decryptedEncounters;
  
    // Map patient IDs to decrypted patient data
    const patientIdToPatientData = {};
    decryptedPatients.forEach((decryptedPatient) => {
      if (decryptedPatient) {
        patientIdToPatientData[decryptedPatient.id] = decryptedPatient.data;
      }
    });
  
    // Combine decrypted encounters with their corresponding patients
    const combinedData = decryptedEncounters.map((decryptedEncounterObj) => {
      if (!decryptedEncounterObj) return null;
  
      const {
        id: encounterId,
        patientId,
        data: decryptedEncounterData,
        timestamp,
        BillingStatus,
      } = decryptedEncounterObj;
  
      const patient = patientIdToPatientData[patientId];
  
      if (!patient || !decryptedEncounterData) {
        console.warn(`Missing data for patient or encounter with ID: ${encounterId}`);
        return null;
      }
  
      return {
        ...decryptedEncounterData,
        patientName: `${patient.patient?.firstName || ''} ${patient.patient?.middleName || ''} ${patient.patient?.lastName || ''}`.trim(),
        date: formatDate(timestamp),
        diagnosisCodes: formatDiagnosisCode(decryptedEncounterData.diagnosisCode || []),
        id: encounterId,
        isBilled: BillingStatus === true,
        patient: patient.patient || {},
        payers: patient.payers || [],
        selectedBillingCodes: [],
      };
    });
  
    // Filter out null values from combined data
    const filteredCombinedData = combinedData.filter(item => item !== null);
  
    // Update state with combined data
    setEncounters((prevEncounters) => [...prevEncounters, ...filteredCombinedData]);
  
    setLoading(false);
  };
  
  
  
  
  

  useEffect(() => {
    loadMoreEncounters();
  }, [uid]);

  useEffect(() => {
    applyFilters(); // Apply filters whenever encounters or filter criteria change
  }, [encounters, fromDate, toDate, filterBilled, filterDuplicates]);

  function formatDiagnosisCode(diagnosisCode) {
    if (Array.isArray(diagnosisCode)) {
      const formattedCodes = diagnosisCode.map(dc => `${dc.code} - ${dc.short_description}`).join(', ');
      return formattedCodes;
    } else if (typeof diagnosisCode === 'string') {
      return diagnosisCode;
    }
    return "Not available"; // Default return if diagnosisCode is neither an array nor a string
  }

  const handleSendClaimsClick = () => {
    setShowCheckboxes(true); // Show checkboxes when button is clicked
  };

  const handleCheckboxChange = (encounterId) => {
    setSelectedEncounters((prevSelected) =>
      prevSelected.includes(encounterId)
        ? prevSelected.filter(id => id !== encounterId)
        : [...prevSelected, encounterId]
    );
  };

  const handleSelectAllChange = async () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      // Load all encounters before selecting
      let allEncounterIds = [];
      while (loadedCount < encounters.length) {
        await loadMoreEncounters();
      }
      allEncounterIds = encounters.filter(encounter => !encounter.isBilled).map(encounter => encounter.id);
      setSelectedEncounters(allEncounterIds);
    } else {
      setSelectedEncounters([]);
    }
  };

  const validateSelectedPatients = () => {
    let errors = {};

    selectedPatientsData.forEach((patientWrapper, index) => {
      let patientErrors = [];

      const patientData = patientWrapper?.patient;
      const payerData = patientWrapper?.payers;
      const { address } = patientData || {};

      // Check if address is missing
      if (!address || !address.address1) {
        patientErrors.push("Address is missing.");
      }

      // Check if memberId is missing in both patient and payer data
      const memberId = patientData?.memberId || payerData?.memberId;
      if (!memberId) {
        patientErrors.push("Member ID is missing.");
      }

      // Check if payer information is missing
      if (!payerData || !payerData.name) {
        patientErrors.push("Payer information is missing.");
      }

      if (patientErrors.length) {
        errors[index] = patientErrors;
      }
    });

    return errors;
  };

  const handleContinueClick = () => {
    const selectedData = encounters.filter(encounter => selectedEncounters.includes(encounter.id));
    const preparedPatientsData = preparePatientBatch(selectedData);
    setSelectedPatientsData(preparedPatientsData);

    const validationErrors = validateSelectedPatients();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      setShowBatchClaimsJSON(true); // Show BatchClaimsJSON when Continue button is clicked
    }
  };

  const formatDateToMMDDYYYY = (date) => {
    const d = new Date(date);
    const month = ("0" + (d.getMonth() + 1)).slice(-2);
    const day = ("0" + d.getDate()).slice(-2);
    const year = d.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const preparePatientBatch = (selectedData) => {
    const patientBatch = selectedData.map((data) => {
      const patientWrapper = data.patient;
      const payerData = data.payers;
      const memberId = patientWrapper.memberId || payerData.memberId;
  
      const {
        firstName,
        middleName,
        lastName,
        dob,
        gender,
        address,
      } = patientWrapper ?? {};
  
      const patientId = data.id ?? {};
      const dos = formatDateToMMDDYYYY(data.dateOfService);
      const dobFormatted = formatDateToMMDDYYYY(dob);
  
      return {
        patient: {
          firstName: firstName || '',
          middleName: middleName || '',
          lastName: lastName || '',
          dob: dobFormatted || '',
          gender: gender || '',
          address: {
            address1: address?.address1 || '',
            address2: address?.address2 || '',
            city: address?.city || '',
            state: address?.state || '',
            zip: address?.zip || '',
          },
        },
        payers: {
          name: payerData?.name || '',
          tradingPartnerId: payerData?.CPID || '',
          memberId: memberId || '',
          secondaryName: payerData?.secondaryPayer?.name || '',
          secondaryMemberId: payerData?.secondaryPayer?.memberId || '',  
        },
        dos: dos || '',
        selectedBillingCodes: data.billingCode || [],
        id: patientId || '',
      };
    });
  
    return patientBatch;
  };  

  const handleUpdatePatientsData = (newData) => {
    setSelectedPatientsData(newData);
  };

  const downloadCSV = () => {
    const headers = [
      "firstName", "middleName", "lastName", "dateOfBirth", "gender",
      "address1", "address2", "city", "state", "zip",
      "payer", "memberId", "secondaryPayer", "secondaryMemberId",
      "dateOfService", "dateOfServiceTo",
      "diagnosisCode1", "diagnosisCode2", "diagnosisCode3",
      "billingCode1", "billingCode2", "billingCode3",
      "chargeAmount1", "chargeAmount2", "chargeAmount3",
      "priorAuthorizationNumber", "resubmitClaimControlNumber"
    ];
    
    const dataForCSV = filteredEncounters.map(enc => {
      const diagnosisCodes = enc.billingCode.reduce((acc, code) => [...acc, ...code.diagnosisCode], []);
      const billingCodes = enc.billingCode.map(code => code.code);
      const chargeAmounts = enc.billingCode.map(code => code.billAmount);
    
      return {
        firstName: enc.patient.firstName || '',
        middleName: enc.patient.middleName || '',
        lastName: enc.patient.lastName || '',
        dateOfBirth: formatDateToMMDDYYYY(enc.patient.dob) || '',
        gender: enc.patient.gender || '',
        address1: enc.patient.address.address1 || '',
        address2: enc.patient.address.address2 || '',
        city: enc.patient.address.city || '',
        state: enc.patient.address.state || '',
        zip: enc.patient.address.zip || '',
        payer: enc.payers.name || '',
        memberId: enc.payers.memberId || '',
        secondaryPayer: enc.payers.secondaryName || '',  
        secondaryMemberId: enc.payers.secondaryMemberId || '',  
        dateOfService: formatDateToMMDDYYYY(enc.date),
        dateOfServiceTo: '', 
        diagnosisCode1: diagnosisCodes[0] || '',
        diagnosisCode2: diagnosisCodes[1] || '',
        diagnosisCode3: diagnosisCodes[2] || '',
        billingCode1: billingCodes[0] || '',
        billingCode2: billingCodes[1] || '',
        billingCode3: billingCodes[2] || '',
        chargeAmount1: chargeAmounts[0] || '',
        chargeAmount2: chargeAmounts[1] || '',
        chargeAmount3: chargeAmounts[2] || '',
        priorAuthorizationNumber: '',
        resubmitClaimControlNumber: ''
      };
    });
    

    const csvContent = [headers.join(",")].concat(dataForCSV.map(row =>
      headers.map(fieldName => row[fieldName]).join(","))
    ).join("\n");

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'billable_encounters.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const applyFilters = () => {
    let filtered = encounters;
  
    if (fromDate) {
      const fromDateTimestamp = new Date(fromDate).getTime();
      filtered = filtered.filter(encounter => new Date(encounter.date).getTime() >= fromDateTimestamp);
    }
  
    if (toDate) {
      const toDateTimestamp = new Date(toDate).getTime();
      filtered = filtered.filter(encounter => new Date(encounter.date).getTime() <= toDateTimestamp);
    }
  
    if (filterBilled) {
      const isBilled = filterBilled === "true";
      filtered = filtered.filter(encounter => encounter.isBilled === isBilled);
    }
  
    if (filterDuplicates) {
      const uniqueEncounters = new Map();
      filtered.forEach(encounter => {
        // Create a unique key based on specific properties to identify duplicates
        const key = `${encounter.providerfirstName}-${encounter.providerlastName}-${encounter.billingCode.map(code => code.code).join(",")}-${encounter.date}-${encounter.controlNumber}`;
        if (!uniqueEncounters.has(key)) {
          uniqueEncounters.set(key, encounter);
        }
      });
      filtered = Array.from(uniqueEncounters.values());
    }
  
    // Sort by date of service in descending order
    filtered.sort((a, b) => new Date(b.date) - new Date(a.date));
  
    setFilteredEncounters(filtered);
    setShowFilterModal(false); // Close the modal after applying filters
  };
  

  const clearFilters = () => {
    setFromDate("");
    setToDate("");
    setFilterBilled("");
    setFilterDuplicates(false);
    setFilteredEncounters(encounters); // Reset to all encounters
    setShowFilterModal(false); // Close the modal after clearing filters
  };

  return (
    <div className="calendarContainer">
      <div className="centerHeader">
        <h3>Billable Encounters</h3>
        <p>Encounters created in Patients, are shown here for Billing.</p>
      </div>
      <button onClick={() => setShowFilterModal(true)}>Add Filters</button>
      {!loading && filteredEncounters.length > 0 && (
        <button onClick={downloadCSV}>Download CSV</button>
      )}

      {loading ? (
        <>
          <HexagonSpinner />
          <p className="authMessage">Decrypting latest Encounters...</p>
        </>
      ) : (
        <>
          {showCheckboxes ? (
            <button onClick={handleContinueClick}>Continue</button>
          ) : (
            <button onClick={handleSendClaimsClick}>Send Claims</button>
          )}
          <table>
            <thead>
              <tr>
                {showCheckboxes && (
                  <th>
                    <input 
                      type="checkbox"
                      className="custom-checkbox"
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                      title='Select All'
                    />
                  </th>
                )}
                <th>Date of Service</th>
                <th>Patient</th>
                <th>Service Line 1</th>
                <th>Provider</th>
                <th>Encounter Type</th>
                <th>Billing Status</th>
              </tr>
            </thead>
            <tbody>
              {filteredEncounters.map((encounter, index) => (
                <tr key={index}>
                  {showCheckboxes && !encounter.isBilled && (
                    <td>
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        checked={selectedEncounters.includes(encounter.id)}
                        onChange={() => handleCheckboxChange(encounter.id)}
                      />
                    </td>
                  )}
                  <td>{encounter.date}</td>
                  <td>{encounter.patientName || "Name not available"}</td>
                  <td>{encounter.billingCode && encounter.billingCode[0] ? encounter.billingCode[0].name : "N/A"}</td>
                  <td>{`${encounter.providerfirstName || "Unknown"} ${encounter.providerlastName || "Provider"}`}</td>
                  <td>{encounter.encounterType}</td>
                  <td>{encounter.isBilled ? "Billed" : "Not Billed"}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {!loading && filteredEncounters.length > loadedCount && (
            <button onClick={loadMoreEncounters}>Load More</button>
          )}
        </>
      )}
      {showBatchClaimsJSON && (
        <div className="overlay">
          <BatchClaimsJSON 
          onClose={() => setShowBatchClaimsJSON(false)} 
          selectedPatientsData={selectedPatientsData} 
          onUpdatePatientsData={handleUpdatePatientsData}
          />
        </div>
      )}
      <Modal
        isOpen={showFilterModal}
        onRequestClose={() => setShowFilterModal(false)}
        contentLabel="Filter Modal"
        className="confirmModal"
      >
        <h2>Add Filters</h2>
        <div className="dateFilters">
          <label htmlFor="fromDate">From Date:</label>
          <input
            type="date"
            id="fromDate"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
          <label htmlFor="toDate">To Date:</label>
          <input
            type="date"
            id="toDate"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
        </div>
        <div>
          <label>Billed:</label>
          <select onChange={(e) => setFilterBilled(e.target.value)} value={filterBilled}>
            <option value="">All</option>
            <option value="true">Billed</option>
            <option value="false">Not Billed</option>
          </select>
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              className="custom-checkbox"
              checked={filterDuplicates}
              onChange={() => setFilterDuplicates(!filterDuplicates)}
            />
            Remove Duplicates
          </label>
        </div>
        <button onClick={applyFilters}>Apply Filters</button>
        <button onClick={clearFilters}>Clear Filters</button>
        <button onClick={() => setShowFilterModal(false)}>Close</button>
      </Modal>
    </div>
  );
};

export default BillableEncounters;
