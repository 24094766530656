import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import useUID from "../../General/useUID";
import { db } from "../../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNotesMedical } from "@fortawesome/free-solid-svg-icons";

const functions = getFunctions();
const decryptFunction = httpsCallable(functions, "decrypt");

function NotesView({ patientId }) {
  const [notes, setNotes] = useState([]);
  const [openNoteId, setOpenNoteId] = useState(null);
  const [uid] = useUID();

  useEffect(() => {
    if (!uid) return;
    const notesRef = collection(
      db,
      "patients",
      uid,
      "patientData",
      patientId,
      "notes"
    );
    const notesQuery = query(notesRef, orderBy("timestamp", "desc"));

    const unsubscribe = onSnapshot(notesQuery, async (querySnapshot) => {
      const notesData = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const { ciphertext, iv, authorName, timestamp } = doc.data();
          const decryptPayload = { ciphertext, iv };
          const decryptedResponse = await decryptFunction(decryptPayload);
          const decryptedText = decryptedResponse.data.text;

          return {
            id: doc.id,
            text: decryptedText,
            authorName,
            timestamp: timestamp?.toDate().toLocaleString(),
          };
        })
      );
      setNotes(notesData);
    });

    return () => unsubscribe();
  }, [patientId, uid]);

  const handleToggleNote = (id) => {
    setOpenNoteId(openNoteId === id ? null : id);
  };

  const truncateText = (text, maxLength) =>
    text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

  return (
    <div className="encountersContainer">
      {notes.length > 0 && (
        <>
          <h3>Notes</h3>
          <div
            style={{
              height: "1px",
              backgroundColor: "black",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          ></div>
        </>
      )}
      {notes.map((note) => (
        <div
          key={note.id}
          className="noteCard"
          onClick={() => handleToggleNote(note.id)}
          title="Click to expand"
        >
          <div className="serviceAndDateContainer">
            <FontAwesomeIcon className="encounterIcon" icon={faNotesMedical} />
            <p>
            <div className="">
            {openNoteId === note.id ? note.text : truncateText(note.text, 25)}
          </div>
              <strong>- </strong> {note.authorName}
            </p>
            <p>{note.timestamp}</p>
          </div>
        
        </div>
      ))}
    </div>
  );
}

export default NotesView;
