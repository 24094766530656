import React, { useState, useEffect, useRef } from "react";
import { auth, db, storage } from "../../firebase";
import {
  collection,
  query,
  onSnapshot,
  addDoc,
  orderBy,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import styles from "./PatientView.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faFilePdf,
  faCamera,
  faCloudArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import useUID from "../General/useUID";

function Files({ patientId, onClose, onFilesCountChanged }) {
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uid, subUserUID] = useUID();
  const [isMobile, setIsMobile] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (!uid) return;

    const filesRef = collection(
      db,
      "patients",
      uid,
      "patientData",
      patientId,
      "files"
    );
    const q = query(filesRef, orderBy("timestamp", "desc"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const filesData = [];
      querySnapshot.forEach((doc) => {
        filesData.push({ id: doc.id, ...doc.data() });
      });
      setFiles(filesData);
    });

    // Detect if the user is on a mobile device
    const checkIfMobile = () => {
      if (/Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        setIsMobile(true);
      }
    };
    checkIfMobile();

    return () => {
      unsubscribe();
    };
  }, [uid, patientId]);

  const getFileType = (fileName) => {
    if (
      fileName.toLowerCase().endsWith(".jpg") ||
      fileName.toLowerCase().endsWith(".jpeg") ||
      fileName.toLowerCase().endsWith(".png")
    ) {
      return "image";
    } else if (fileName.toLowerCase().endsWith(".pdf")) {
      return "pdf";
    }
    return "other";
  };

  const handleFileUpload = async (file) => {
    if (!file) return;

    setUploading(true);

    // Check if the file was captured via the camera
    const isCameraCapture =
      fileInputRef.current && fileInputRef.current.capture;

    // If the file is from the camera, rename it
    if (isCameraCapture) {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split("T")[0]; // YYYY-MM-DD
      const fileExtension = file.name.split(".").pop();
      const newFileName = `phoneshot_${formattedDate}.${fileExtension}`;

      // Create a new File object with the new name
      file = new File([file], newFileName, { type: file.type });
    }

    const filePath = `PatientDocuments/${uid}/${file.name}`;
    const storageReference = storageRef(storage, filePath);

    await uploadBytes(storageReference, file);

    const downloadUrl = await getDownloadURL(storageReference);

    const filesRef = collection(
      db,
      "patients",
      uid,
      "patientData",
      patientId,
      "files"
    );
    const docRef = await addDoc(filesRef, {
      name: file.name,
      url: downloadUrl,
      timestamp: new Date(),
    });
    const fileId = docRef.id;

    const logData = {
      timestamp: Date.now(),
      patientId: patientId,
      fileId: fileId,
      activity: "File added",
      activityType: "fileAdded",
      uid: uid,
    };

    await addDoc(collection(db, "users", uid, "activityLogs"), logData);

    setUploading(false);
  };

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    handleFileUpload(file);
    // Reset the input value to allow re-uploading the same file
    e.target.value = "";
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.classList.add(styles.dropboxDragover);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.classList.remove(styles.dropboxDragover);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    handleFileUpload(file);
  };

  const handleDeleteFile = async (fileId) => {
    const fileRef = doc(
      db,
      "patients",
      uid,
      "patientData",
      patientId,
      "files",
      fileId
    );
    await deleteDoc(fileRef);
  };

  return (
    <>
      <div className="closeButtonContainer">
      <button onClick={onClose} className="filesCloseButton">
        X
      </button>
      <p className="closeBarNav">Files</p>
    </div>
    <div className="notesContainer">
      <div className={styles.filesLayout}>
        {/* Upload Controls */}
        <div className={styles.uploadBox}>
          <form className={styles.uploadForm}>
            <input
              type="file"
              accept="image/*"
              capture="environment"
              ref={fileInputRef}
              onChange={handleFileInputChange}
              style={{ display: "none" }}
            />
            {/* Conditionally render based on isMobile */}
            {isMobile ? (
              <div>
                <button
                  type="button"
                  className={styles.uploadButton}
                  onClick={handleFileInputClick}
                >
                  <FontAwesomeIcon icon={faCloudArrowUp} /> Click to Upload
                </button>
              </div>
            ) : (
              <div
                className={styles.dropbox}
                onClick={handleFileInputClick}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                title="Upload files"
              >
                <div className={styles.dropBoxContent}>
                  <FontAwesomeIcon
                    icon={faCloudArrowUp}
                    style={{ height: "3rem", marginBottom: ".5rem" }}
                  />
                </div>
                <p>Click to browse or drop files in here.</p>
              </div>
            )}
            {/* Camera Button */}
            <button
              type="button"
              className={styles.cameraButton}
              onClick={handleFileInputClick}
            >
              <FontAwesomeIcon icon={faCamera} /> Take Photo of Insurance Card
            </button>
          </form>
        </div>

        {/* Existing Files List */}
        <div className={styles.filesList}>
          <h3>Saved Files</h3>
          {files.length > 0 ? (
            <ul>
              {files.map((file) => (
                <li key={file.id} className={styles.fileItem}>
                  <div className={styles.fileContent}>
                    {getFileType(file.name) === "image" && (
                      <img
                        src={file.url}
                        alt={file.name}
                        className={styles.fileImagePreview}
                      />
                    )}
                    {getFileType(file.name) === "pdf" && (
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className={styles.filePdfPreview}
                      />
                    )}
                    <a
                      href={file.url}
                      download={file.name}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {file.name}
                    </a>
                  </div>
                  <button
                    onClick={() => handleDeleteFile(file.id)}
                    className={styles.deleteFileButton}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p>No files available.</p>
          )}
        </div>
      </div>
    </div>
    </>
  );
}

export default Files;
