import React from 'react';
import aetna from '../assets/logos/aetna.png';
import fidelis from '../assets/logos/fidelis.png';
import medicaid from '../assets/logos/medicaid.png';
import molina from '../assets/logos/molina.png';
import bcbs from '../assets/logos/bcbs.png';
import hf from '../assets/logos/hf-logo.png';
import medicare from '../assets/logos/medicaire.png';
import oscar from '../assets/logos/oscar.png';
import cigna from '../assets/logos/cigna.png';
import kaiser from '../assets/logos/kaiser.png';
import metro from '../assets/logos/metro.png';
import wellcare from '../assets/logos/wellcare.png';

const InsuranceLogos = () => {
  const logos = [
    { src: aetna, alt: 'Aetna Insurance' },
    { src: fidelis, alt: 'Fidelis Care' },
    { src: medicaid, alt: 'Medicaid' },
    { src: molina, alt: 'Molina Healthcare' },
    { src: bcbs, alt: 'Blue Cross Blue Shield' },
    { src: hf, alt: 'Healthfirst' },
    { src: medicare, alt: 'Medicare' },
    { src: oscar, alt: 'Oscar Health' },
    { src: cigna, alt: 'Cigna' },
    { src: kaiser, alt: 'Kaiser Permanente' },
    { src: metro, alt: 'MetroPlus Health' },
    { src: wellcare, alt: 'Wellcare' }
  ];

  return (
    <section className="insurance-logos">
      <div className="container-logo">
        <h3 className="section-title">
          Connect to over 3,500 Top US-Based insurance providers
        </h3>
        <div className="logo-grid">
          {logos.map((logo, index) => (
            <div key={index} className="logo-container">
              <img
                src={logo.src}
                alt={logo.alt}
                className="logo-image"
              />
            </div>
          ))}
        </div>
      </div>

      <style jsx>{`
        .insurance-logos {
          width: 100%;
          background-color: #ffffff;
          padding: 48px 0;
        }

        .container-logo {
          max-width: 1200px;
          margin: 0 auto;
          padding: 0 20px;
          margin-top: 40px;
        }

        .logo-grid {
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          gap: 32px;
          align-items: center;
          justify-items: center;
        }

        .logo-container {
          width: 100%;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: transparent;
        }

        .logo-image {
          max-height: 48px;
          max-width: 120px;
          width: auto;
          height: auto;
          object-fit: contain;
          opacity: 1;
          transition: all 0.3s ease;
        }

        .logo-image:hover {
          transform: scale(1.05);
        }

        @media (max-width: 1200px) {
          .logo-grid {
            grid-template-columns: repeat(4, 1fr);
          }
        }

        @media (max-width: 768px) {
          .logo-grid {
            grid-template-columns: repeat(3, 1fr);
            gap: 24px;
          }

          .logo-image {
            max-height: 40px;
            max-width: 100px;
          }
        }

        @media (max-width: 480px) {
          .logo-grid {
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
          }

          .insurance-logos {
            padding: 32px 0;
          }

          .section-title {
            margin-bottom: 24px;
          }

          .logo-image {
            max-height: 35px;
            max-width: 90px;
          }
        }
      `}</style>
    </section>
  );
};

export default InsuranceLogos;