import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import useUID from "../../../General/useUID";
import { db } from "../../../../firebase";
import HexagonSpinner from "../../../General/Animations/Hexspinner";

const functions = getFunctions();
const decryptFunction = httpsCallable(functions, "decrypt");

function IntakeView({ patientId, clinicToken }) {
  const [formData, setFormData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [uid, subUserUID, isLoading, error] = useUID();

  useEffect(() => {
    if (!uid || !patientId || !clinicToken) return;

    const fetchIntakeForms = async () => {
      try {
        setIsLoadingData(true);

        // Use clinicToken directly:
        const intakeFormsRef = collection(db, "clinics", clinicToken, "intakeForms");
        const intakeFormsQuery = query(intakeFormsRef, where("patientId", "==", patientId));
        const intakeFormsSnapshot = await getDocs(intakeFormsQuery);

        if (!intakeFormsSnapshot.empty) {

          const intakeFormsData = await Promise.all(
            intakeFormsSnapshot.docs.map(async (docSnap) => {
              const { ciphertext, iv } = docSnap.data();
              const decryptPayload = { ciphertext, iv };
              const decryptedResponse = await decryptFunction(decryptPayload);
              const decryptedIntake = decryptedResponse.data.data;
              return decryptedIntake?.length ? decryptedIntake : [];
            })
          );

          setFormData(intakeFormsData);
        } else {
        }
      } catch (error) {
      } finally {
        setIsLoadingData(false);
      }
    };

    fetchIntakeForms();
  }, [patientId, clinicToken, uid]);

  return (
    <div className="encountersContainer">
      {formData.length > 0 && (
        <>
          <h3>Intake Form Results</h3>
          <div
            style={{
              height: "1px",
              backgroundColor: "black",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          ></div>
        </>
      )}
      {isLoadingData ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <HexagonSpinner />
        </div>
      ) : (
        formData.map((form, formIndex) => (
          <div key={formIndex}>
            {form.map((input) => (
              <div key={input.id}>
                {input.fields.map((item, itemIndex) => (
                  <React.Fragment key={itemIndex}>
                    {item.isVisible && (
                      <div>
                        {["text","phone","email","date","time","choice","textarea"].includes(item.type) && (
                          <p>
                            <span style={{ opacity: 0.75 }}>{item.label}:</span>{" "}
                            <span>{item.value}</span>
                          </p>
                        )}
                        {item.type === "signature" && (
                          <div>
                            <p style={{ opacity: 0.75 }}>{item.label}:</p>
                            <img src={item.value} alt="signature" />
                          </div>
                        )}
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            ))}
          </div>
        ))
      )}
    </div>
  );
}

export default IntakeView;
