import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { auth, db, storage } from "../../../firebase";
import { useState, useEffect, useRef } from "react";
import { doc, onSnapshot, updateDoc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Topbar from "../Topbar";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CardUpdateForm from "./CardUpdateForm";
import "./settings.modules.css";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { collection, query, orderBy, deleteField } from "firebase/firestore";
import moment from "moment";
import useUID from "../useUID";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faUpload } from '@fortawesome/free-solid-svg-icons';

const functions = getFunctions();
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

function Settings() {
  const navigate = useNavigate();
  const [uid, subUserUID, isLoading, error] = useUID();
  const [user, setUser] = useState(null);
  const [showCardUpdateForm, setShowCardUpdateForm] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [totalRequests, setTotalRequests] = useState(0);
  const [last24HoursRequests, setLast24HoursRequests] = useState(0);
  const [billingInfo, setBillingInfo] = useState({
    totalRequests: 0,
    totalSpend: 0,
    last24HoursRequests: 0,
    last24HoursCharge: 0,
  });
  const [showDowngradeConfirmation, setShowDowngradeConfirmation] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [companyImageUrl, setCompanyImageUrl] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // Controls edit mode
  const [formData, setFormData] = useState({ organizationName: "", phone: "" }); // Temp form state


  const fileInputRef = useRef(null);

  useEffect(() => {
    if (!uid) return;
    const userRef = doc(db, "users", uid);
    const unsubscribe = onSnapshot(userRef, (doc) => {
      if (doc.exists()) {
        const userData = doc.data();
        setUser(userData);
        
        // Add missing fields if they don't exist
        let updateNeeded = false;
        const updatedData = {};
        if (!userData.organizationName) {
          updatedData.organizationName = "";
          updateNeeded = true;
        }
        if (!userData.phone) {
          updatedData.phone = "";
          updateNeeded = true;
        }
        if (updateNeeded) {
          updateDoc(userRef, updatedData);
        }
      }
    });

    const companyImageRef = doc(db, "customerCompanyImage", uid);
    const unsubscribeCompanyImage = onSnapshot(companyImageRef, (doc) => {
      if (doc.exists() && doc.data().imageUrl) {
        setCompanyImageUrl(doc.data().imageUrl);
      }
    });

    return () => {
      unsubscribe();
      unsubscribeCompanyImage();
    };
  }, [uid]);

  useEffect(() => {
    if (!uid) return;
  
    let userRef;
    if (subUserUID) {
      userRef = doc(db, "users", uid, "subusers", subUserUID);
    } else {
      userRef = doc(db, "users", uid);
    }
  
    const unsubscribe = onSnapshot(userRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        setUser(userData);
        setUserRole(userData.role || 'nurse'); // Default to 'nurse' if no role is set
      }
    });
  
    return () => unsubscribe();
  }, [uid, subUserUID]);
  

  useEffect(() => {
    if (!uid) return;

    const requestsRef = collection(db, "users", uid, "API");
    const q = query(requestsRef, orderBy("timestamp", "desc"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let total = 0;
      let last24HoursTotal = 0;

      querySnapshot.forEach((doc) => {
        const requestData = doc.data();
        const apiRequests = requestData.APIRequests;

        if (apiRequests) {
          const apiRequestsCount = parseInt(apiRequests, 10);
          total += apiRequestsCount;

          const timestamp = requestData.timestamp.toDate();
          const now = new Date();
          const differenceInHours = (now - timestamp) / 1000 / 60 / 60;

          if (differenceInHours <= 24) {
            last24HoursTotal += apiRequestsCount;
          }
        }
      });

      setTotalRequests(total);
      setLast24HoursRequests(last24HoursTotal);
    });

    return () => unsubscribe();
  }, [uid]);

  const handlePasswordReset = () => {
    sendPasswordResetEmail(getAuth(), user.email)
      .then(() => {
        alert("Email sent to reset password.");
      })
      .catch((error) => {
        console.error("Error sending password reset email:", error);
        alert("Error sending email to reset password.");
      });
  };

  const updateCard = async (paymentMethodId) => {
    try {
      const functions = getFunctions();
      const updateCardFunction = httpsCallable(functions, "updateCard");
      await updateCardFunction({ paymentMethodId: paymentMethodId });
    } catch (error) {
      console.error("Error updating card on server:", error);
      throw error;
    }
  };

  const handleSubscriptionCancellation = async () => {
    try {
      const userRef = doc(db, "users", uid);
      await updateDoc(userRef, { subscriptionId: deleteField() });

      const addLogFunction = httpsCallable(functions, 'addLog');
      await addLogFunction({ uid: uid, message: 'Account Deleted' });

      await auth.currentUser.delete()
        .then(() => {
        })
        .catch((error) => {
          console.error("Error deleting Firebase Auth User:", error);
        });

      setShowCancelConfirmation(false);
      alert("Your subscription has been canceled. Your account has been deleted.");
    } catch (error) {
      console.error("Error canceling subscription:", error);
      alert("Error canceling subscription.");
    }
  };

    // Fetch the current logo URL from Firestore when the component loads
    useEffect(() => {
      if (!uid) return;

      const companyImageRef = doc(db, "customerCompanyImage", uid);
      const unsubscribe = onSnapshot(companyImageRef, (docSnapshot) => {
          if (docSnapshot.exists() && docSnapshot.data().imageUrl) {
              setCompanyImageUrl(docSnapshot.data().imageUrl);
              sessionStorage.setItem("companyLogo", docSnapshot.data().imageUrl); // Update sessionStorage
          }
      });

      return () => unsubscribe();
  }, [uid]);

  const handleImageUpload = async (e) => {
      const file = e.target.files[0];
      if (!file) return;

      if (file.type !== 'image/png') {
          alert('Only PNG files are allowed. Sorry!');
          return;
      }

      setUploading(true);
      const storageRef = ref(storage, `customerCompanyImage/${uid}/${file.name}`);
      await uploadBytes(storageRef, file);

      const downloadUrl = await getDownloadURL(storageRef);

      // Update Firestore and the state
      const companyImageRef = doc(db, "customerCompanyImage", uid);
      await setDoc(companyImageRef, { imageUrl: downloadUrl }, { merge: true });

      setCompanyImageUrl(downloadUrl); // Update the state immediately
      sessionStorage.setItem("companyLogo", downloadUrl); // Update sessionStorage

      // Notify Topbar to refresh
      window.dispatchEvent(new CustomEvent("logoUpdated", { detail: { logoUrl: downloadUrl } }));

      setUploading(false);
  };

  const triggerFileInput = () => fileInputRef.current.click();
  const renderCancelConfirmation = () => {
    return (
      <div className="popup">
        <div className="popup-content">
          <h3>Are you sure you want to delete your account?</h3>
          <div className="flex-container">
            <button className="danger" onClick={handleSubscriptionCancellation}>Yes, delete my account and loose access to my data.</button>
            <button className="primary" onClick={() => setShowCancelConfirmation(false)}>No, Keep my account.</button>
          </div>
        </div>
      </div>
    );
  };

  if (!user) {
    return <p></p>;
  }

  const handleDowngradeToFreebie = async () => {
    setShowDowngradeConfirmation(true);
  };

  const confirmDowngrade = async () => {
    setShowDowngradeConfirmation(false);
    try {
      const downgradeFunction = httpsCallable(functions, "downgradetoFreebie");
      const response = await downgradeFunction({ uid: uid });

      if (!response.data.success) {
        throw new Error(response.data.error);
      }

      setUser({ ...user, AccountTier: 'freebie' });
      alert(response.data.message);
    } catch (error) {
      console.error("Error downgrading to freebie tier:", error);
      alert("Error downgrading to freebie tier.");
    }
  };

  const renderDowngradeConfirmationModal = () => {
    return (
      <div className="popup">
        <div className="popup-content">
          <h2>Confirm Downgrade</h2>
          <p>Are you sure you want to downgrade to the Freebie tier?</p>
          <p>By downgrading, you will lose access to premium features.</p>
          <div>
            <button onClick={confirmDowngrade}>Confirm</button>
            <button onClick={() => setShowDowngradeConfirmation(false)}>Cancel</button>
          </div>
        </div>
      </div>
    );
  };

  const toggleTwoFactorAuth = async () => {
    setShowConfirmationModal(false);
    const updatedStatus = !user.twoFactorAuthEnabled;
    const userRef = doc(db, 'users', uid);

    try {
      let updateData = {
        twoFactorAuthEnabled: updatedStatus ? deleteField() : false,
        phoneNumber: deleteField()
      };

      await updateDoc(userRef, updateData);
      setUser(prev => ({
        ...prev,
        twoFactorAuthEnabled: updatedStatus ? undefined : false,
        phoneNumber: null
      }));

      if (updatedStatus) {
        navigate('/signin');
      }
    } catch (error) {
      console.error('Failed to update two-factor setting:', error);
    }
  };

  const handleFieldChange = (field, value) => {
    setUser(prev => ({
      ...prev,
      [field]: value,
    }));

    const userRef = doc(db, "users", uid);
    updateDoc(userRef, { [field]: value });
  };

  const chargeMultiplier = user.AccountTier === 'Enterprise' ? 35 : user.AccountTier === 'Professional' ? 40 : 0;



  const handleSave = async () => {
    if (!uid) return;

    const userRef = doc(db, "users", uid);

    try {
      await updateDoc(userRef, {
        organizationName: formData.organizationName,
        phone: formData.phone,
      });

      setUser((prev) => ({
        ...prev,
        organizationName: formData.organizationName,
        phone: formData.phone,
      }));

      setIsEditing(false); // Exit edit mode
    } catch (error) {
      console.error("Error updating user details:", error);
      alert("Failed to update details. Please try again.");
    }
  };

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleRemoveLogo = async () => {
    if (!uid) return;
  
    try {
      // Remove the logo from Firestore
      const companyImageRef = doc(db, "customerCompanyImage", uid);
      await setDoc(companyImageRef, { imageUrl: deleteField() }, { merge: true });
  
      // Update the state
      setCompanyImageUrl(null);
      sessionStorage.removeItem("companyLogo");
  
      // Notify Topbar to refresh
      window.dispatchEvent(new CustomEvent("logoUpdated", { detail: { logoUrl: null } }));
  
      alert("Logo removed successfully.");
    } catch (error) {
      console.error("Error removing logo:", error);
      alert("Failed to remove logo. Please try again.");
    }
  };

  return (
    <div className='background'>
      <Topbar />
      <div className="contentContainer">
        <div className="settings-content">
          <div className="bigName">
            {user.firstName} {' '}{user.lastName}
          </div>

        {/* Logo Upload Section */}
        <div
          className="upload-box"
          onClick={triggerFileInput}
          onDrop={handleImageUpload}
          onDragOver={(e) => e.preventDefault()}
        >
          {uploading ? (
            <p>Uploading...</p>
          ) : companyImageUrl ? (
            <>
              <img src={companyImageUrl} alt="Company Logo" className="company-logo" />
              <button className="secondary remove-logo-btn" onClick={handleRemoveLogo}>
                Remove Logo
              </button>
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faUpload} size="3x" color="#0147DF" />
              <p>Add your company's logo. Select or drag and drop an image</p>
            </>
          )}
        </div>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleImageUpload}
        />

           {/* Organization Name and Phone Section */}
           <div className="input-group-row leftRow">
            <div className="input-field">
              <label>Organization Name:</label>
              {isEditing ? (
                <input
                  type="text"
                  value={formData.organizationName}
                  onChange={(e) => handleInputChange("organizationName", e.target.value)}
                />
              ) : (
                <span>{user?.organizationName || "Not Provided"}</span>
              )}
            </div>
            <div className="input-field">
              <label>Phone:</label>
              {isEditing ? (
                <input
                  type="text"
                  value={formData.phone}
                  onChange={(e) => handleInputChange("phone", e.target.value)}
                />
              ) : (
                <span>{user?.phone || "Not Provided"}</span>
              )}
            </div>
          </div>

          {/* Add/Edit Buttons */}
          {!isEditing && (
            <button
              className="primary"
              onClick={() => setIsEditing(true)}
            >
              {user?.organizationName && user?.phone ? "Edit" : "Please Add"} Company Information
            </button>
          )}
          {isEditing && (
            <div>
              <button className="primary" onClick={handleSave}>Save</button>
              <button
                className="secondary"
                onClick={() => {
                  setIsEditing(false);
                  setFormData({
                    organizationName: user?.organizationName || "",
                    phone: user?.phone || "",
                  });
                }}
              >
                Cancel
              </button>
            </div>
          )}

          <div>
            <span>Email: </span>
            <span>{user.email}</span>
          </div>
          <button onClick={() => setShowConfirmationModal(true)}>
            {user.twoFactorAuthEnabled ? 'Disable Two-Factor Authentication' : 'Enable Two-Factor Authentication'}
          </button>
          <div>
            <button className="primary" onClick={handlePasswordReset}>Reset Password</button>
          </div>
          {user.AccountTier === 'Enterprise' &&
            <button onClick={() => window.location.href='/api'}>API</button>
          }
          <button onClick={() => window.location.href='/users'}>Users</button>
          <button onClick={() => window.location.href='/payment'}>Upgrade</button>
          <section className="section">
            <h2>Billing</h2>
            <div>
              <span>Account Tier: </span>
              <span>{user.AccountTier}</span>
            </div>
            <div>
              <span>Total API requests: </span>
              <span>{totalRequests}</span>
            </div>
            <div>
              <span>Total spend: </span>
              <span>${totalRequests ? (totalRequests * (chargeMultiplier / 100)).toFixed(2) : "0.00"}</span>
            </div>
            <div>
              <span>API requests last 24 hours: </span>
              <span>{last24HoursRequests}</span>
            </div>
            <div>
              <span>Charges last 24 hours: </span>
              <span>${last24HoursRequests ? (last24HoursRequests * (chargeMultiplier / 100)).toFixed(2) : "0.00"}</span>
            </div>
          </section>
          
          <section className="section">
            <h2>Subscription</h2>
            <div>
              <button className="primary" onClick={() => setShowCardUpdateForm(!showCardUpdateForm)}>
                Change Payment Method
              </button>
              {showCardUpdateForm && (
                <div className="popup">
                  <div className="popup-content">
                    <h3>Update Card Details</h3>
                    <Elements stripe={stripePromise}>
                      <CardUpdateForm handleSubmit={updateCard} />
                    </Elements>
                    <button className="primary" onClick={() => setShowCardUpdateForm(false)}>Close</button>
                  </div>
                </div>
              )}
            </div>
            {user && user.AccountTier !== 'freebie' && userRole === 'admin' && !subUserUID && (
              <button onClick={handleDowngradeToFreebie}>Downgrade to Freebie</button>
            )}
            {showDowngradeConfirmation && renderDowngradeConfirmationModal()}
            {userRole === 'admin' && !subUserUID && (
              <button onClick={() => setShowCancelConfirmation(true)}>Delete Account</button>
            )}
            {showCancelConfirmation && renderCancelConfirmation()}
            {showConfirmationModal && (
              <div className="popup">
                <div className="popup-content">
                  <p>Are you sure you want to {user.twoFactorAuthEnabled ? 'disable' : 'enable'} two-factor authentication?</p>
                  <button onClick={toggleTwoFactorAuth}>Confirm</button>
                  <button onClick={() => setShowConfirmationModal(false)}>Cancel</button>
                </div>
              </div>
            )}
          </section>


        </div>
      </div>
    </div>
  );
}

export default Settings;
