import React, { useState, useEffect, useRef } from 'react';
import { auth, db } from "../../firebase";
import { useNavigate, useParams, Link } from "react-router-dom";
import "./AUTH.css"; // Import styles without assigning to a variable
import styles from "./AUTH.css";
import Smalllogo from './smalllogo';
import Footer from "../General/Home/Footer";
import logo from '../../popularis_logos/Popularis_logo_wide.png';
import { Helmet } from 'react-helmet';
import { collection, getDocs, query, where, doc, getDoc, updateDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { getFunctions, httpsCallable } from 'firebase/functions';
import AuthHexSpinner from "./AuthHexSpinner";
import {
  getAuth,
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
  onAuthStateChanged,
  RecaptchaVerifier,
  PhoneAuthProvider,
  updatePhoneNumber,
  GoogleAuthProvider,
  signInWithPopup
} from "firebase/auth";
import InputMask from 'react-input-mask';
import CodeInputBoxes from './CodeInputBoxes';
import googleLogo from '../../components/Website/assets/googleLogo.png'
import '../Website/subcomponents/MovingGradientBackground.css';

const functions = getFunctions();

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [show2FAInput, setShow2FAInput] = useState(false);
  const [showAddPhone, setShowAddPhone] = useState(false);
  const [show2FAPrompt, setShow2FAPrompt] = useState(false);
  const navigate = useNavigate();
  const { nav } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const recaptchaVerifierRef = useRef(null);
  const [effectiveUID, setEffectiveUID] = useState("");
  const [recaptchaVerifierReady, setRecaptchaVerifierReady] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [codeInputs, setCodeInputs] = useState(Array(6).fill(""));
  const [UserUID, setUserUID] = useState("");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserUID(user.uid);
        handleUserLogin(user);
        localStorage.setItem('authUser', JSON.stringify(user));
      } else {
        localStorage.removeItem('authUser');
      }
    });

    window.addEventListener('storage', handleStorageChange);

    return () => {
      unsubscribe();
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleStorageChange = (event) => {
    if (event.key === 'authUser') {
      const user = JSON.parse(event.newValue);
      if (user) {
        handleUserLogin(user);
      } else {
        setUserUID("");
      }
    }
  };

  const handleUserLogin = async (user) => {
    setIsLoading(true);
    try {
      const userUID = user.uid;
      const { parentUID, isSubuser, subuserUID } = await getEffectiveUID(user.email, userUID);
      const effectiveUID = isSubuser ? subuserUID : userUID;
      setEffectiveUID(effectiveUID);
      sessionStorage.setItem('parentUID', parentUID);
      sessionStorage.setItem('effectiveUID', effectiveUID);

      let userDataDoc;
      if (isSubuser && parentUID && subuserUID) {
        userDataDoc = await getDoc(doc(db, "users", parentUID, "subusers", subuserUID));
      } else {
        userDataDoc = await getDoc(doc(db, "users", userUID));
      }

      if (!userDataDoc.exists()) {
        throw new Error("User account exists but associated data could not be retrieved.");
      }

      setError("");
      const userData = userDataDoc.data();
      sessionStorage.setItem('userRole', userData.role);  // Set user role from Firestore
      handleAuthenticationState(userData, userUID, isSubuser);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const signIn = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let geolocationResult = null;
  
    try {
      try {
        geolocationResult = await httpsCallable(functions, 'verifyGeolocation')();
      } catch (geolocationError) {
        // Skip geolocation verification errors and proceed with login
        console.error("Geolocation verification error: ", geolocationError);
      }
  
      await performFirebaseSignIn(geolocationResult);
    } catch (error) {
      console.error("SignIn Error: ", error);
      setError("Error during sign-in. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  

  const performFirebaseSignIn = async (verifyGeolocationResult) => {
    setIsLoading(true);
    try {
      const userCredential = await setPersistence(auth, browserSessionPersistence)
        .then(() => signInWithEmailAndPassword(auth, email, password))
        .catch((error) => {
          switch (error.code) {
            case 'auth/user-not-found':
            case 'auth/wrong-password':
              throw new Error("Incorrect credentials. Please check your email and password.");
            case 'auth/network-request-failed':
              throw new Error("Network error. Please check your internet connection.");
            default:
              throw new Error("Authentication failed. Please try again later.");
          }
        });

      const userUID = userCredential.user.uid;
      setUserUID(userUID);

      const { parentUID, isSubuser, subuserUID } = await getEffectiveUID(email, userUID);
      const effectiveUID = isSubuser ? subuserUID : userUID;
      setEffectiveUID(effectiveUID);
      sessionStorage.setItem('parentUID', parentUID);
      sessionStorage.setItem('effectiveUID', effectiveUID);

      let userDataDoc;
      if (isSubuser && parentUID && subuserUID) {
        userDataDoc = await getDoc(doc(db, "users", parentUID, "subusers", subuserUID));
      } else {
        userDataDoc = await getDoc(doc(db, "users", userUID));
      }

      if (!userDataDoc.exists()) {
        throw new Error("User account exists but associated data could not be retrieved.");
      }

      setError("");
      const userData = userDataDoc.data();
      handleAuthenticationState(userData, userUID, isSubuser);

      logSignIn(userUID, parentUID, isSubuser, email, verifyGeolocationResult).catch(logError => {
        console.error("Log SignIn Error:", logError);
      });
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAuthenticationState = (userData, userUID, isSubuser) => {
    if (userData.twoFactorAuthEnabled === false) {
      determineNavigationPath();
    } else if (userData.twoFactorAuthEnabled && userData.phoneNumber) {
      sessionStorage.setItem('2FAVerified', false);
      setShow2FAInput(true);
      setShow2FAPrompt(false);
      setShowAddPhone(false);
      sendVerificationCode(userData.phoneNumber);
    } else if (typeof userData.twoFactorAuthEnabled === 'undefined' || !userData.twoFactorAuthEnabled) {
      setShow2FAPrompt(true);
      setShow2FAInput(false);
      setShowAddPhone(false);
    } else {
      setError("Unexpected authentication state. Please contact support.");
    }
  };

  const verifyCode = async (fullCode) => {
    setIsLoading(true);
    try {
      if (!verificationId) {
        throw new Error("Verification process not initialized.");
      }
      const phoneCredential = PhoneAuthProvider.credential(verificationId, fullCode);
      await updatePhoneNumber(auth.currentUser, phoneCredential);

      const { parentUID, isSubuser, subuserUID } = await getEffectiveUID(email, auth.currentUser.uid);
      let docRef;
      if (isSubuser && parentUID && subuserUID) {
        docRef = doc(db, "users", parentUID, "subusers", subuserUID);
      } else {
        docRef = doc(db, "users", auth.currentUser.uid);
      }

      const updateData = {
        phoneNumber: auth.currentUser.phoneNumber,
        twoFactorAuthEnabled: true
      };

      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, updateData);
      } else {
        await setDoc(docRef, updateData);
      }

      sessionStorage.setItem('2FAVerified', true);
      determineNavigationPath();
    } catch (error) {
      console.error("Error verifying code:", error);
      setError(`Failed to verify the code. ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const addPhoneNumberFor2FA = async (phoneNumber) => {
    setIsLoading(true);
    try {
      if (!auth.currentUser) throw new Error("No user is currently signed in.");
      if (!recaptchaVerifierRef.current) throw new Error("RecaptchaVerifier is not initialized.");

      const email = auth.currentUser.email;
      const { parentUID, isSubuser, subuserUID } = await getEffectiveUID(email, auth.currentUser.uid);
      const effectiveUID = isSubuser ? subuserUID : auth.currentUser.uid;

      const formattedPhone = `+1${phoneNumber.replace(/\D/g, '')}`;
      setUserPhoneNumber(formattedPhone);

      const phoneAuthProvider = new PhoneAuthProvider(auth);
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(formattedPhone, recaptchaVerifierRef.current);
      setVerificationId(verificationId);
      setShow2FAInput(true);
    } catch (error) {
      console.error("Error during phone number verification:", error);
      setError(`Failed to verify phone number. ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const sendVerificationCode = async (phoneNumber) => {
    setIsLoading(true);
    try {
      if (!recaptchaVerifierRef.current) {
        console.error("RecaptchaVerifier is not initialized. Please reload this page.");
        setError("RecaptchaVerifier is not initialized. Please reload this page.");
        setIsLoading(false);
        return;
      }
      const formattedPhone = `+1${phoneNumber.replace(/\D/g, '')}`;
      setUserPhoneNumber(formattedPhone)
      const phoneProvider = new PhoneAuthProvider(auth);
      const verificationId = await phoneProvider.verifyPhoneNumber(formattedPhone, recaptchaVerifierRef.current);
      setVerificationId(verificationId);
      setShow2FAInput(true);
    } catch (error) {
      setError("Failed to send verification code. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const resendVerificationCode = async () => {
    setIsLoading(true);
    try {
      const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
      const userData = userDoc.data();
      const now = new Date();
      const lastRequestTime = userData.lastVerificationRequestTime
        ? userData.lastVerificationRequestTime.toDate()
        : null;
      const fiveMinutesAgo = new Date(now.getTime() - 5 * 60 * 1000);

      if (lastRequestTime && lastRequestTime > fiveMinutesAgo) {
        throw new Error(
          'You can only request a new verification code once every 5 minutes.'
        );
      }

      const phoneProvider = new PhoneAuthProvider(auth);
      const verificationId = await phoneProvider.verifyPhoneNumber(
        userPhoneNumber,
        recaptchaVerifierRef.current
      );
      setVerificationId(verificationId);
      setError('');
      await updateDoc(doc(db, 'users', auth.currentUser.uid), {
        lastVerificationRequestTime: serverTimestamp(),
      });
    } catch (error) {
      console.error('Error resending verification code:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getEffectiveUID = async (email, userUID) => {
    const directUserDoc = await getDoc(doc(db, "users", userUID));
    if (directUserDoc.exists() && directUserDoc.data().email === email) {
      return {
        parentUID: userUID,
        isSubuser: false,
        subuserUID: undefined
      };
    }

    const usersCollection = collection(db, "users");
    const allUsersSnapshot = await getDocs(usersCollection);
    for (let userDoc of allUsersSnapshot.docs) {
      const subusersCollection = collection(userDoc.ref, "subusers");
      const q = query(subusersCollection, where("email", "==", email));
      const subuserSnapshot = await getDocs(q);

      if (!subuserSnapshot.empty) {
        const subuserDoc = subuserSnapshot.docs[0];
        return {
          parentUID: userDoc.id,
          isSubuser: true,
          subuserUID: subuserDoc.id
        };
      }
    }
    return {
      parentUID: userUID,
      isSubuser: false,
      subuserUID: undefined
    };
  };

  useEffect(() => {
    if (auth && !recaptchaVerifierRef.current) {
      try {
        recaptchaVerifierRef.current = new RecaptchaVerifier(auth, 'recaptcha-container', {
          'size': 'invisible',
          'callback': (response) => {
          }
        });
      } catch (error) {
        console.error("RecaptchaVerifier setup error:", error);
      }
    }
  }, [auth]);

  const determineNavigationPath = () => {
    let redirectPath = '/home';
    if (nav === 'support') {
      redirectPath = '/usersupport';
    } else if (nav === 'ipa') {
      redirectPath = '/ipaapplication';
    }
    navigate(redirectPath);
  };

  const handleDisable2FAAndNavigate = async () => {
    setIsLoading(true);
    try {
      const uid = sessionStorage.getItem('effectiveUID');
      const { parentUID, isSubuser, subuserUID } = await getEffectiveUID(email, uid);
      const docRef = isSubuser ? doc(db, 'users', parentUID, 'subusers', subuserUID) : doc(db, 'users', uid);
      await updateDoc(docRef, {
        twoFactorAuthEnabled: false,
      });
      const userDataDoc = await getDoc(docRef);
      if (!userDataDoc.exists()) throw new Error("User data not found.");
      const userData = userDataDoc.data();
      determineNavigationPath(userData);
    } catch (error) {
      console.error("Error updating 2FA preference: ", error);
      setError("Failed to update 2FA settings.");
    } finally {
      setIsLoading(false);
    }
  };

  const logSignIn = async (userUID, parentUID, isSubuser, email, verifyGeolocationResult) => {
    const city = verifyGeolocationResult.data.fullGeoIPData?.city?.names?.en || "Unknown city";
    const country = verifyGeolocationResult.data.fullGeoIPData?.country?.names?.en || "Unknown country";
    const state = verifyGeolocationResult.data.fullGeoIPData?.subdivisions?.[0]?.names?.en || "Unknown state";
    const postalCode = verifyGeolocationResult.data.fullGeoIPData?.postal?.code || "Unknown postal code";
    const latitude = verifyGeolocationResult.data.fullGeoIPData?.location?.latitude;
    const longitude = verifyGeolocationResult.data.fullGeoIPData?.location?.longitude;
    const isp = verifyGeolocationResult.data.fullGeoIPData?.traits?.isp;
    const connectionType = verifyGeolocationResult.data.fullGeoIPData?.traits?.connectionType;
    const currentTime = new Date().toISOString();
    const ipAddress = verifyGeolocationResult.data.ip || "";

    const logMessage = isSubuser
      ? `${email} (subuser) logged in from ${city}, ${state}, ${country} (IP: ${ipAddress}, Postal Code: ${postalCode}, Lat/Long: ${latitude},${longitude}, ISP: ${isp}, Connection Type: ${connectionType}). Login Time: ${currentTime}`
      : `Admin User logged in from ${city}, ${state}, ${country} (IP: ${ipAddress}, Postal Code: ${postalCode}, Lat/Long: ${latitude},${longitude}, ISP: ${isp}, Connection Type: ${connectionType}). Login Time: ${currentTime}`;
    const addLogFunction = httpsCallable(functions, 'addLog');
    await addLogFunction({
      uid: parentUID,
      message: logMessage,
      loginDetails: JSON.stringify(verifyGeolocationResult.data)
    });
  };

  const handleEnable2FA = () => {
    setShow2FAPrompt(false);
    setShowAddPhone(true);
  };

  const maskedPhoneNumber = userPhoneNumber
    ? `${userPhoneNumber.slice(0, -2).replace(/\d/g, "*")}${userPhoneNumber.slice(-2)}`
    : "Not Available";

  const handleGoogleSignIn = async () => {
    setIsLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const userUID = user.uid;

      const geolocationResult = await httpsCallable(functions, 'verifyGeolocation')();

      await logSignIn(userUID, userUID, false, user.email, geolocationResult);

      navigate("/home");
    } catch (error) {
      console.error("Google Sign-In Error: ", error);
      setError("Error during Google sign-in. Please try again.");
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Sign In | Popularis Health</title>
        <meta name="description" content="Sign into your Popularis account." />
        <meta name="keywords" content="" />
        <meta property="og:title" content="Sign In | Popularis Health" />
        <meta property="og:description" content="Sign into Popularis." />
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/popularishealth.appspot.com/o/Popularis_logo_single.png?alt=media&token=e079bdf2-360b-42da-9ef2-1cdbdeb474cf" />
        <meta property="og:url" content="https://popularishealth.com/signin" />
      </Helmet>
      <div className="background auth-background-overflow ">
        {/* <a href="/" className="logoWrapper">
          <img src={logo} alt="Logo" className="logo" />
        </a> */}
        <div className="AUTH-container">
          <div>
            <Smalllogo />
          </div>
          {
            show2FAInput ? (
              <form onSubmit={verifyCode}>
                <h3 className="section-headline">A Verification Code has been sent to your phone number:</h3>
                <h3 className="section-headline">{maskedPhoneNumber}</h3>
                <CodeInputBoxes onChange={setCodeInputs} onSubmit={verifyCode} />
                {
                  error && <p className={styles["error-message"]}>{error}</p>
                }
                <Link onClick={resendVerificationCode} className="forgot-password-link">
                  Resend Code
                </Link>
                <Link to="/support" className="forgot-password-link">Support</Link>
              </form>
            ) : show2FAPrompt ? (
              <div className="content-center">
                <h3>Do you want to enable Two-Factor SMS Authentication?</h3>
                <p>While not required by HIPAA, it is highly recommended to prevent unauthorized access of health data.</p>
                <div style={{ flexDirection: "row" }} >
                  <button onClick={handleEnable2FA}>Yes</button>
                  <button onClick={handleDisable2FAAndNavigate}>No</button>
                </div>
              </div>
            ) : showAddPhone ? (
              <form onSubmit={(e) => {
                e.preventDefault();
                const phoneNumber = e.target.elements['phone-number'].value;
                addPhoneNumberFor2FA(phoneNumber);
              }}>
                <div className="content-center">
                  <h3>Please enter a valid US phone number that can receive SMS.</h3>
                  <p>This will be the phone linked to the account to prevent unauthorized access.</p>
                  <div className="input-group-row">
                    <div className="input-field">
                      <label htmlFor="phone">Phone</label>
                      <InputMask
                        id="phone"
                        name="phone-number"
                        mask="(999) 999-9999"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="(123) 456-7890"
                      />
                    </div>
                  </div>
                  <button type="submit">Link Phone Number</button>
                  <div id="recaptcha-container"></div>
                </div>
              </form>
            ) : (
              <form onSubmit={signIn}>
                <h1 className="heading">Welcome back</h1>
                <div className="input-group-row">
                  <div className="input-field">
                    <label htmlFor="address1">Email:</label>
                    <input
                      type="email"
                      placeholder=""
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className={styles["input-field"]}
                      style={{ fontSize: '16px', minWidth: "15rem" }} /* Set font size to 16px */
                    />
                  </div>
                </div>
                <div className="input-group-row">
                  <div className="input-field">
                    <label htmlFor="address1">Password:</label>
                    <input
                      type="password"
                      placeholder=""
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className={styles["input-field"]}
                      style={{ fontSize: '16px', minWidth: "15rem"  }} /* Set font size to 16px */
                    />
                  </div>
                </div>
                {error && <p className={styles["error-message"]}>{error}</p>}
                {isLoading ? (
                  <AuthHexSpinner />
                ) : (
                  <>
                    <button type="submit" style={{ margin: '1rem'}} className='signup-btn'>
                      Sign In
                    </button>
                  
                      <div className="divider-wrapper">
                        <hr className="divider-line" />
                        <span className="divider-text">or</span>
                        <hr className="divider-line" />
                      </div>
                    <button type="button" className="signInGoogle-button" onClick={handleGoogleSignIn}>
                      <img src={googleLogo} alt="Google Logo" className="google-logo" />
                      Continue with Google
                    </button>
                    <Link to="/forgot-password" className="forgot-password-link">
                        Forgot Password?
                      </Link>
                      <Link to="/signup" className="signup-link">
                        Create a Popularis Account
                      </Link>
                  </>
                )}
              
              </form>
            )
          }
        </div>
        <div className="g-recaptcha" data-sitekey={process.env.RECAPTCHA_SITE_KEY} style={{ marginBottom: "20px" }}></div>
        <div id="recaptcha-container"></div>

        <div className="links-signup">
            <a href="/privacy">Privacy Policy</a> <a> | </a>
            <a href="/terms">Terms and Conditions</a>
          </div>

        <div className="gradient-skewed-top"></div>

      </div>
    </>
  );
};

export default SignIn;
