import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faGlobeAmericas, 
  faGlobeEurope, 
  faGlobeAsia, 
  faGlobeAfrica 
} from '@fortawesome/free-solid-svg-icons';
import 'flag-icon-css/css/flag-icons.min.css';
import './LanguageSelector.css';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const languages = [
    { code: 'en', label: 'English', region: 'americas', flag: 'us' },
    { code: 'es', label: 'Español', region: 'americas', flag: 'es' },
    { code: 'fr', label: 'Français', region: 'europe', flag: 'fr' },
    { code: 'pt', label: 'Português', region: 'americas', flag: 'br' },
    { code: 'nl', label: 'Nederlands', region: 'europe', flag: 'nl' },
    { code: 'el', label: 'Ελληνικά', region: 'europe', flag: 'gr' },
    { code: 'it', label: 'Italiano', region: 'europe', flag: 'it' },
    { code: 'de', label: 'Deutsch', region: 'europe', flag: 'de' },
    { code: 'ar', label: 'العربية', region: 'africa', flag: 'ae' },
    { code: 'hi', label: 'हिन्दी', region: 'asia', flag: 'in' },
    { code: 'bn', label: 'বাংলা', region: 'asia', flag: 'bd' },
    { code: 'ja', label: '日本語', region: 'asia', flag: 'jp' },
    { code: 'ru', label: 'Русский', region: 'europe', flag: 'ru' },
    { code: 'zh', label: '中文 (简体)', region: 'asia', flag: 'cn' },
  ];

  const regionIcons = {
    americas: faGlobeAmericas,
    europe: faGlobeEurope,
    asia: faGlobeAsia,
    africa: faGlobeAfrica,
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setShowDropdown(false);
  };

  const currentLanguage = i18n.language || 'en';
  const currentRegion = languages.find((lang) => lang.code === currentLanguage)?.region || 'americas';

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="language-selector" ref={dropdownRef}>
      <button onClick={() => setShowDropdown(!showDropdown)} className="language-selector-button">
        <FontAwesomeIcon icon={regionIcons[currentRegion]} /> 
        <span className="current-language">{currentLanguage.toUpperCase()}</span>
      </button>
      {showDropdown && (
        <ul className="language-dropdown">
          {languages.map((lang) => (
            <li
              key={lang.code}
              className={`language-option ${currentLanguage === lang.code ? 'selected' : ''}`}
              onClick={() => changeLanguage(lang.code)}
            >
              <span className={`flag-icon flag-icon-${lang.flag}`} />
              <span className="language-label">{lang.label}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageSelector;
