import React, { useEffect, useState } from 'react';
import { collection, query, getDocs, orderBy, where } from 'firebase/firestore';
import useUID from '../../General/useUID';
import { db } from "../../../firebase";
import Topbar from '../../General/Topbar';
import HelpArticleLink from "../../Articles/HelpArticleLink";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import AppointmentDetails from './AppointmentDetails';
import Modal from 'react-modal';
import { useNavigate, useParams } from 'react-router-dom';
import { parse } from "date-fns";
import HexagonSpinner from '../../General/Animations/Hexspinner';

const localizer = momentLocalizer(moment);

Modal.setAppElement('#root');

function AppointmentsCalendar() {
  const { clinicIdURL } = useParams();
  const [uid] = useUID();
  const [appointments, setAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalStyle, setModalStyle] = useState({});
  const [clinics, setClinics] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState(clinicIdURL || 'all');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!uid) return;

    const fetchClinics = async () => {
      try {
        const clinicsRef = collection(db, "clinics");
        const clinicsQuery = query(clinicsRef, where("ownerId", "==", uid));
        const clinicSnapshot = await getDocs(clinicsQuery);

        if (!clinicSnapshot.empty) {
          const clinicsData = clinicSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setClinics(clinicsData);
        }
      } catch (error) {
        console.error("Error fetching clinics:", error);
      }
    };

    fetchClinics();
  }, [uid]);

  useEffect(() => {
    if (!uid || clinics.length === 0) return;

    const fetchAppointments = async () => {
      try {
        const allAppointments = [];

        for (const clinic of clinics) {
          if (clinicIdURL && clinic.id !== clinicIdURL && clinicIdURL !== 'all') continue;
          const appointmentsRef = collection(db, "clinics", clinic.id, "appointments");
          const appointmentsQuery = query(appointmentsRef, orderBy("time", "desc"));
          const appointmentsSnapshot = await getDocs(appointmentsQuery);

          if (!appointmentsSnapshot.empty) {
            const clinicAppointments = await Promise.all(appointmentsSnapshot.docs.map(async (appointmentDoc) => {
              const data = appointmentDoc.data();
              const dateString = `${data.date} ${new Date().getFullYear()} ${data.time}`;
              const start = parse(dateString, "MMMM do yyyy HH:mm", new Date());
              const end = new Date(start.getTime() + (data.appointmentDuration || 30) * 60000);

              if (isNaN(start) || isNaN(end)) {
                console.error(`Invalid date for appointment ID: ${appointmentDoc.id}`, data);
                return null;
              }

              return {
                id: appointmentDoc.id,
                title: `${data.appointmentType}`,
                start,
                end,
                allDay: false,
                clinicName: clinic.clinicName,
                ...data,
                clinicId: clinic.id
              };
            }));

            allAppointments.push(...clinicAppointments.filter(app => app !== null));
          }
        }

        setAppointments(allAppointments);
      } catch (error) {
        console.error("Error fetching appointments:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAppointments();
  }, [uid, clinics, clinicIdURL]);

  const handleSelectEvent = (event, e) => {
    setSelectedAppointment(event);
    const rect = e.target.getBoundingClientRect();
    const tooltipHeight = 200; // Adjust according to the actual height of your tooltip
    const tooltipWidth = 400; // Adjust according to the actual width of your tooltip
    let top = rect.top + window.scrollY;
    let left = rect.left + window.scrollX;

    // Ensure the tooltip is within the viewport
    const bottomEdge = rect.bottom + tooltipHeight;
    const rightEdge = rect.right + tooltipWidth;

    // Adjust position if the event is high up on the screen
    if (bottomEdge > window.innerHeight) {
        top = window.innerHeight - tooltipHeight - 100; // 10px margin
    } else {
        top = rect.bottom + window.scrollY;
    }

    if (rightEdge > window.innerWidth) {
        left = window.innerWidth - tooltipWidth - 200; // 10px margin
    } else {
        left = rect.left + window.scrollX;
    }

    const modalStyle = {
      position: 'absolute',
      top: `${top}px`,
      left: `${left}px`,
      transform: 'translate(-50%, 0)',
    };
    setModalStyle(modalStyle);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const filteredAppointments = selectedClinic === 'all' 
    ? appointments 
    : appointments.filter(appointment => appointment.clinicId === selectedClinic);

  const handleClinicChange = (e) => {
    const selectedClinicName = e.target.value;
    if (selectedClinicName === 'all') {
        setSelectedClinic('all');
        navigate('/appointmentscalendar/all');
    } else {
        const selectedClinic = clinics.find(clinic => clinic.id === selectedClinicName);
        if (selectedClinic) {
            setSelectedClinic(selectedClinic.id);
            navigate(`/appointmentscalendar/${selectedClinic.id}`);
        }
    }
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const backgroundColor = '#00316f';
    const style = {
      backgroundColor: backgroundColor,
      borderRadius: '4px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'block'
    };
    return {
      style: style
    };
  };

  return (
    <>      

      <Topbar />
      
      <div style={{ marginTop: '3rem' }} className="calendarContainer">
      <div className="closeButtonContainer">
              <button onClick={() => navigate('/patients')} className="filesCloseButton">
                X
              </button>
              <p className="closeBarNav">Appointments Calendar</p>
            </div>
        <div>
          <div className="showByDropdown">
            <label>
              Select a Clinic:
              <select
                value={selectedClinic}
                onChange={handleClinicChange}
                style={{ marginLeft: "1rem" }}
              >
                <option value="all">All Clinics</option>
                {clinics.map((clinic) => (
                  <option key={clinic.id} value={clinic.id}>
                    {clinic.clinicName}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div style={{ height: 'calc(100vh - 17rem)' }}>
            {loading ? (
              <>
                <HexagonSpinner />
              </>
            ) : (
              <Calendar
                localizer={localizer}
                events={filteredAppointments}
                startAccessor="start"
                endAccessor="end"
                defaultView="week"
                onSelectEvent={handleSelectEvent}
                eventPropGetter={eventStyleGetter}
              />
            )}
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Appointment Details"
          className="calendarModal"
          overlayClassName="Overlay"
          style={{ content: modalStyle }}
        >
          <button onClick={closeModal} className="filesCloseButton">X</button>
          {selectedAppointment && (
            <AppointmentDetails 
              appointment={selectedAppointment} 
            />
          )}
        </Modal>
        {/* <HelpArticleLink 
          article={{ 
            title: 'Appointments', 
            link: 'https://popularishealth.com/article/appointments' 
          }} 
        /> */}
      </div>
    </>
  );
}

export default AppointmentsCalendar;
