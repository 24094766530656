import React, { useState, useEffect, useRef } from 'react';
import '../Website.css';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Helmet } from 'react-helmet';

import logo from '../../../popularis_logos/Popularis_logo_wide.png';
import heroImage from '../assets/heroImage.png'
import hipaa from '../assets/hipaa-compliance.png';
import FeaturesList from '../subcomponents/FeaturesList';
import doc1 from '../assets/doc1.png';
import doc2 from '../assets/doc2.png';
import doc3 from '../assets/doc3.png';
import Hero from '../assets/finalHero2.mp4';
import { Link, useLocation } from 'react-router-dom';
import AllInOneSection from '../subcomponents/AllInOneSection';
import AmericanFlag from '../assets/americanFlag.png';
import ArticleCarousel from '../subcomponents/ArticlesCarousel';
import ExcelScreenshot from '../assets/excel.JPG'
import UiGrid from '../subcomponents/UiGrid';
import InsuranceLogos from '../subcomponents/InsuranceLogos';
import PatientsIpad from '../assets/iPad_patients.png';
import PlayButtonIcon from '../assets/play-button.svg';
import SecuritySection from '../subcomponents/Security Section';
import APIIntegrationsSection from '../subcomponents/APISection';
import Laptop from '../assets/Macbook_patients.png';
import { useNavigate } from "react-router-dom";
import { faHeart, faFileInvoiceDollar, faFileExcel, faStaffSnake, faLock, faFlask, faCreditCard, faSyncAlt, faPlayCircle, faHeadset, faDesktop, faClock, faCheckCircle, faPlug, faClipboardList, faGlobeAmericas, faServer, faNotesMedical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Confetti from '../../General/Animations/Confetti';
import madeinusa from '../assets/madeinamerica.png'

const SalesPage = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState({});
  const sectionRefs = useRef({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [name, setName] = useState( localStorage.getItem('name') ||
  new URLSearchParams(window.location.search).get('name') ||
  '');
  const [company, setCompany] = useState( localStorage.getItem('company') ||
  new URLSearchParams(window.location.search).get('company') ||
  '');
  const [email, setEmail] = useState(
    localStorage.getItem('email') ||
    new URLSearchParams(window.location.search).get('email') ||
    ''
  );
  const [stats, setStats] = useState([
    { value: 0, target: 99.95, label: 'Claims Acceptance Rate', suffix: '%' },
    { value: 60, target: 100, label: 'Claims Sent', prefix: '', suffix: 'k+' },
    { value: 30, target: 50, label: 'Eligibility Requests Made', suffix: 'k+' },
    { value: 0, target: 25, label: 'Patients Warehoused', prefix: '', suffix: 'k+' },
    { value: 0, target: 10, label: 'Charges Sent', prefix: '$', suffix: 'M+' },
    { value: 0, target: 8, label: 'Revenue to Providers', prefix: '$', suffix: 'M+' },
    { value: 0, target: 13.5, label: 'Most Claims Sent in a Day', prefix: '', suffix: 'k+' },
    { value: 0, target: 100, label: 'Clinics Serviced', prefix: '', suffix: '+' },

  ]);

  const [date, setDate] = useState(localStorage.getItem('date') || '');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   // Initialize Firebase Functions
  //   const functions = getFunctions();
  //   const trackVisitor = httpsCallable(functions, 'trackVisitor');

  //   // Call the Cloud Function when the component mounts
  //   trackVisitor()
  //     .then((result) => {
  //     })
  //     .catch((error) => {
  //     });
  // }, []);


  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsLoading(true);
    const functions = getFunctions();
    const sendDemoRequest = httpsCallable(functions, 'sendEmailDemo');

    try {
      await sendDemoRequest({ name, email, date, company });
      setIsSubmitted(true);
      if (onSubmitted) onSubmitted();
    } catch (error) {
      console.error('Error sending demo request: ', error);
      setErrors({ submit: 'There was an error submitting your request. Please try again later.' });
    } finally {
      setIsLoading(false);
    }
  };

  // Generate time options for dropdown (9 AM - 5 PM in 30-minute intervals)
  const generateTimeOptions = () => {
    const times = [];
    for (let hour = 9; hour <= 17; hour++) {
      ['00', '30'].forEach((minutes) => {
        if (hour === 17 && minutes !== '00') return; // Exclude times after 5:00 PM
        const timeString = `${hour.toString().padStart(2, '0')}:${minutes}`;
        times.push(timeString);
      });
    }
    return times;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsButtonVisible(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsVisible((prev) => ({ ...prev, [entry.target.id]: entry.isIntersecting }));
        });
      },
      { threshold: 0.1 }
    );

    Object.values(sectionRefs.current).forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    localStorage.setItem('email', email);
  }, [email]);

  useEffect(() => {
    if (isVisible['stats']) {
      const interval = setInterval(() => {
        setStats(prevStats =>
          prevStats.map(stat => ({
            ...stat,
            value: stat.value < stat.target ? stat.value + 1 : stat.target
          }))
        );
      }, 30);

      return () => clearInterval(interval);
    }
  }, [isVisible['stats']]);

  const handleNavigation = (page) => {
    if (email) {
      if (!validateEmail(email)) {
        alert('Please enter a valid email address.');
      } else {
        navigate(`/${page}?email=${encodeURIComponent(email)}`);
      }
    } else {
      navigate(`/${page}`);
    }
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handlePlayVideo = () => {
    setIsVideoPlaying(true);
  };

  // Handler for Contact Sales click
  const handleContactSalesClick = () => {
    navigate('/contact/sales');
  };


  // Form validation
  const validateForm = () => {
    const errors = {};
    if (!name.trim()) errors.name = 'Name is required';
    if (!email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email address is invalid';
    }
    if (!date) errors.date = 'Preferred demo date is required';
    return errors;
  };

  return (
    <div className="landing-page">
        <Helmet>
        <title>Popularis | Sales</title>
        <meta name="description" content="Your one-stop-shop for patient care, billing, and clinic management." />
        <meta
          name="keywords"
          content="Popularis, Popularis Health, EMR, Medical Claims, Eligibility, patient eligibility, encrypted medical records, encrypted EMR, claim status, Medical billing, Patient management software, Cloud-based EMR solutions, Medical coding, Claims, Patient records, PHI, Popularis, Popularis Health, Healthcare, Intake forms, Patient eligibility, Insurance eligibility"
        />
        <meta property="og:title" content="Popularis | Sales" />
        <meta property="og:description" content="Your one-stop-shop for patient care, billing, and clinic management." />
        <meta property="og:url" content="https://www.popularishealth.com/sales" />
      </Helmet>

          <div>
        <main className="landing-content">
          <section className="hero-section" ref={(el) => (sectionRefs.current['hero'] = el)} id="hero">
            <link href="https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;600;700&display=swap" rel="stylesheet" />
            <div className="hero-background">
              <div className="hero-angle"></div>
            </div>
            <div className="hero-wrapper">
              <div className="hero-content-left">
                <div className='hero-text-left'>
                  <h1 className="hero-title textLeft">Clinical Software to grow your practice</h1>
                  <p className={`hero-description ${isVisible['hero'] ? 'animate' : ''}`}>
                    Join other providers that are using Popularis as their one-stop-shop for patient care, billing, and clinic management.
                  </p>


                  <div className="signup-container salesPageSignUp">
                {!isSubmitted ? (
                  <>
                    <h3 className='text-sales-container salesBlack'>Request a Demo</h3>
                    <form onSubmit={handleSubmit}>
                      {/* Name */}
                      <div className="input-field salesBlack">
                        <label className="input-field" htmlFor="name">Your Name:</label>
                        <input
                          id="name"
                          type="text"
                          value={name}
                          onChange={(event) => {
                            setName(event.target.value);
                            setErrors({ ...errors, name: '' });
                          }}
                          className={errors.name ? 'error' : ''}
                          required
                        />
                        {errors.name && <p className="error-message">{errors.name}</p>}
                      </div>

                      <div className="input-field salesBlack">
                        <label className="input-field" htmlFor="name">Company:</label>
                        <input
                          id="company"
                          type="text"
                          value={company}
                          onChange={(event) => {
                            setCompany(event.target.value);
                            setErrors({ ...errors, name: '' });
                          }}
                          className={errors.name ? 'error' : ''}
                          required
                        />
                        {errors.name && <p className="error-message">{errors.name}</p>}
                      </div>

                      {/* Email */}
                      <div className="input-field salesBlack">
                        <label className="input-field" htmlFor="email">Email:</label>
                        <input
                          id="email"
                          type="email"
                          value={email}
                          onChange={(event) => {
                            setEmail(event.target.value);
                            setErrors({ ...errors, email: '' });
                          }}
                          className={errors.email ? 'error' : ''}
                          required
                        />
                        {errors.email && <p className="error-message">{errors.email}</p>}
                      </div>

                      {/* Preferred Demo Date */}
                      <div className="input-field salesBlack">
                        <label className="input-field" htmlFor="date">Preferred Demo Date:</label>
                        <input
                          id="date"
                          type="date"
                          value={date}
                          onChange={(event) => {
                            setDate(event.target.value);
                            setErrors({ ...errors, date: '' });
                          }}
                          className={errors.date ? 'error' : ''}
                          required
                        />
                        {errors.date && <p className="error-message">{errors.date}</p>}
                      </div>

                   

                      {/* Submit Button */}
                      {errors.submit && <p className="error-message">{errors.submit}</p>}
                      <button type="submit" disabled={isLoading}>
                        {isLoading ? 'Submitting...' : 'Request Demo'}
                      </button>
                    </form>
                  </>
                ) : (
                  <div className="confetti-container">
                    <Confetti />
                    <h2 className='salesBlack'>Thank you for your request! We will get back to you soon.</h2>
                  </div>
                )}
              </div>
                  
                </div>
             
              </div>
              <div className="hero-content-right">
                <div className="animated-hero">
                  <video
                    src={Hero}
                    autoPlay
                    playsInline
                    muted
                    loop
                    x-webkit-airplay="deny"
                    title="Animation showing different user types such as doctor, nurse, tech, or lab worker surrounded by the icons of the apps the user uses most."
                  ></video>
                </div>
              </div>
            </div>
            <div className="white-layer"></div>
          </section>
          <section
          className="sandbox-section"
          ref={(el) => (sectionRefs.current['sandbox'] = el)}
          id="sandbox"
        >
          <div className="container-logo">
            <div className="sandbox-content">
              <div className="sandbox-text">
                <h2 className={`section-title ${isVisible['sandbox'] ? 'animate' : ''}`}>
                  Try Our Sandbox Version
                </h2>
                <h2 className='section-sandbox-subtitle'>
                  No Strings Attached
                </h2>

                {/* Wrap each icon in an icon-wrapper div */}
                <div className="sandbox-item">
                  <div className="icon-wrapper">
                    <FontAwesomeIcon icon={faCreditCard} className='sandbox-icon' />
                  </div>
                  <span>No credit card required.</span>
                </div>
                <div className="sandbox-item">
                  <div className="icon-wrapper">
                    <FontAwesomeIcon icon={faFlask} className='sandbox-icon' />
                  </div>
                  <span>Send test transactions and use test patients.</span>
                </div>
                <div className="sandbox-item">
                  <div className="icon-wrapper">
                    <FontAwesomeIcon icon={faCheckCircle} className='sandbox-icon' />
                  </div>
                  <span>35 free live transactions.</span>
                </div>
                <div className="sandbox-item">
                  <div className="icon-wrapper">
                    <FontAwesomeIcon icon={faSyncAlt} className='sandbox-icon' />
                  </div>
                  <span>Test systems against your own, running them in parallel.</span>
                </div>

                <div className="sandbox-button-wrapper">
                  <button
                    onClick={() => handleNavigation('signup')}
                    className="sandbox-button"
                  >
                    <FontAwesomeIcon icon={faPlayCircle} style={{ marginRight: '0.5rem' }} />
                    Try the Sandbox &gt;
                  </button>
                </div>
              </div>
              <div className="sandbox-image">
                <img src={Laptop} alt="Laptop showing sandbox version" />
              </div>
            </div>
          </div>
        </section>



        <InsuranceLogos />

        <AllInOneSection />

        <SecuritySection isVisible={isVisible} />

        <section className="stats-section bg-white skewed-section" ref={(el) => (sectionRefs.current['stats'] = el)} id="stats">
          <div className="golden-container">
            <h2 className={`section-title ${isVisible['stats'] ? 'animate' : ''}`}>
              Our Impact in Numbers
            </h2>
            <div className="stats-grid">
              {stats.map((stat, index) => (
                <div
                  className={`stat-item ${isVisible['stats'] ? 'animate' : ''}`}
                  key={index}
                  style={{ animationDelay: `${index * 0.2}s` }}
                >
                  <h3 className="stat-value">
                    {stat.prefix}{stat.value}{stat.suffix}
                  </h3>
                  <p className="stat-label">{stat.label}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section
          className="made-in-usa-section skewed-flag-section"
          ref={(el) => (sectionRefs.current['madeInUsa'] = el)}
          id="made-in-usa"
          style={{
            backgroundImage: `url(${AmericanFlag})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            color: 'white',
          }}
        >
          <div className="golden-container">
            <h2 className={`security-section ${isVisible['madeInUsa'] ? 'animate' : ''}`}>
              We are proud to be Made in the USA
            </h2>
            <div className="process-steps">
              {[
                {
                  title: '3,500 US-based Insurance Payers',
                  icon: faFileInvoiceDollar,
                },
                {
                  title: 'Only US-based Customer Support',
                  icon: faHeadset,
                },
                {
                  title: 'Servers and Infrastructure Only in USA',
                  icon: faServer,
                },
                {title: 'US Created Encrypotion Algorithms',
                icon: faLock, }
              ].map((item, index) => (
                <div
                  className={`step-3 fourlayout ${isVisible['madeInUsa'] ? 'animate' : ''}`}
                  key={index}
                  style={{ animationDelay: `${index * 0.2}s` }}
                >
                  <FontAwesomeIcon icon={item.icon} className="step-icon-dark" />
                  <h3 className="step-title-dark">{item.title}</h3>
                </div>
              ))}
            </div>
            <div className='AmericaContainer'>
              <img src={madeinusa} className='madeInAmerica'/>
            </div>
          </div>
        </section>

        <UiGrid />
        <APIIntegrationsSection isVisible={isVisible} />

        <section className="why-popularis-section bg-grey skewed-section" ref={(el) => (sectionRefs.current['whyPopularis'] = el)} id="why-popularis">
          <div className="golden-container">
            <h2 className={`section-title ${isVisible['whyPopularis'] ? 'animate' : ''}`}>
              Why Popularis?
            </h2>
            <div className="popularis-features-grid">
              {[
                {
                  title: 'Intuitive Design',
                  description: 'Our interface is simple and easy to use, making your workflow seamless.',
                  icon: faDesktop,
                },
                {
                  title: 'Saves Hours Daily',
                  description: 'Automated and batched workflows save you hours daily on administrative tasks.',
                  icon: faClock,
                },
                {
                  title: 'Instant Eligibility',
                  description: 'Check patient eligibility in real-time, reducing delays and improving efficiency.',
                  icon: faCheckCircle,
                },
                {
                  title: 'Batch Claims',
                  description: 'Send claims in bulk with ease, reducing the manual workload for billing staff.',
                  icon: faFileInvoiceDollar,
                },
                {
                  title: 'Easy API Integrations',
                  description: 'Integrate with external systems effortlessly using our powerful API.',
                  icon: faPlug,
                },
                {
                  title: 'Custom Intake Forms',
                  description: 'Create and customize patient intake forms tailored to your clinic\'s needs.',
                  icon: faClipboardList,
                },
              ].map((feature, index) => (
                <div
                  className={`feature-card ${isVisible['whyPopularis'] ? 'animate' : ''}`}
                  key={index}
                  style={{ animationDelay: `${index * 0.2}s` }}
                >
                  <FontAwesomeIcon icon={feature.icon} className="feature-icon" />
                  <h3 className="feature-title">{feature.title}</h3>
                  <p className="feature-description">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="bg-white skewed-section youtubeSkew" ref={(el) => (sectionRefs.current['demo'] = el)} id="demo">
          <div className="golden-container">
            <h2 className={`section-title ${isVisible['demo'] ? 'animate' : ''}`}>
              A Demo of Popularis
            </h2>
            <div className="video-wrapper">
              {!isVideoPlaying ? (
                <div className="video-thumbnail" onClick={handlePlayVideo}>
                  <img src={PatientsIpad} alt="Popularis Demo" className="ipad-image" />
                  <img src={PlayButtonIcon} alt="Play Button" className="play-button" />
                </div>
              ) : (
                <div className="video-container">
                  <iframe
                    src="https://www.youtube.com/embed/DKn5ix1xaPc?autoplay=1"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              )}
            </div>
          </div>
        </section>

        {/* New Data Integration Section */}
        <section
          className="data-integration-section"
          ref={(el) => (sectionRefs.current['dataIntegration'] = el)}
          id="data-integration"
        >
          <div className="golden-container">
            <div className="data-integration-content">
              <div className="data-integration-text">
                <h2 className={`section-title ${isVisible['dataIntegration'] ? 'animate' : ''}`}>
                  Easy Migration
                </h2>
                <div className="expect-item">
                  <FontAwesomeIcon icon={faFileExcel} className="feature-icon-integration" />
                  <div className="feature-text-integration">
                    <h4>Batch Imports via XLSX</h4>
                    <p>Quickly import large datasets using Excel files to streamline your workflow.</p>
                  </div>
                </div>
                <div className="expect-item">
                  <FontAwesomeIcon icon={faPlug} className="feature-icon-integration" />
                  <div className="feature-text">
                    <h4>API Import</h4>
                    <p>Integrate your systems seamlessly with our robust API for real-time data exchange.</p>
                  </div>
                </div>
                <div className="expect-item">
                  <FontAwesomeIcon icon={faHeadset} className="feature-icon-integration"/>
                  <div className="feature-text">
                    <h4>Technical Customer Support</h4>
                    <p>Our expert support team is ready to assist you with any technical challenges.</p>
                  </div>
                </div>
              </div>
              <div className="data-integration-image">
                <img src={ExcelScreenshot} alt="Excel Screenshot for Batch Import" />
              </div>
            </div>
          </div>
        </section>




        <section className="bg-grey">
          <div className="golden-container">
            <h2 className="section-title">Enterprise Solutions Tailored for You</h2>
            <div className="enterprise-features">
              <div className="enterprise-feature">
                <FontAwesomeIcon icon={faPlug} className="enterprise-icon" />
                <h4>Free API Integration Support</h4>
                <p>With the Enterprise plan, our dedicated team ensures seamless integration with your systems at no additional cost.</p>
              </div>
              <div className="enterprise-feature">
                <FontAwesomeIcon icon={faHeadset} className="enterprise-icon" />
                <h4>24/7 US-Based Customer Service</h4>
                <p>Reach our knowledgeable and friendly support team anytime, anywhere.</p>
              </div>
              <div className="enterprise-feature">
                <FontAwesomeIcon icon={faCheckCircle} className="enterprise-icon" />
                <h4>Unlimited Providers & Reduced Transaction Costs</h4>
                <p>No limits on providers in your account, plus a 30% cost reduction per transaction.</p>
              </div>
            </div>
            <div className="enterprise-button-wrapper">
              <Link to="/pricing" className="enterprise-button">
                Learn About Our Pricing
              </Link>
            </div>
          </div>
        </section>



        <div className="tile-support tile bg-grey skewed-section" data-id="support">
          <a href='/support'>
            <div className="golden-container">
              <div className="tile-support">
                <div className="support-wrapper">
                  <div className="support-icon-features">
                    <FontAwesomeIcon style={{ height: "100px" }} icon={faHeadset} />
                  </div>
                  <div>
                    <h1 className="section-title">The Popularis Team is always by your side.</h1>
                    <p className="typography-label-alt-dark support-text">US-based support is always just a call, email, or text away.</p>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
          </main>
      </div>
    </div>
  );
};

export default SalesPage;
