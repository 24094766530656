import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

export const encountersApiSpecification = {
  "openapi": "3.0.0",
  "info": {
    "title": "Encounters API Callback",
    "version": "1.0.0",
    "description": "This API endpoint is designed to handle callbacks for encounter data processing and forwarding."
  },
  "paths": {
    "/encountersCallback": {
      "post": {
        "summary": "Process and forward encounter data",
        "description": "Receives encounter data, fetches additional information from Firestore, and forwards the data to another API based on stored API specs.",
        "requestBody": {
          "required": true,
          "content": {
            "application/json": {
              "schema": {
                "type": "object",
                "properties": {
                  "uid": {
                    "type": "string",
                    "description": "User ID of the patient."
                  },
                  "patientId": {
                    "type": "string",
                    "description": "Patient's unique identifier."
                  },
                  "encounterData": {
                    "type": "object",
                    "properties": {
                      "diagnosisCode": {
                        "type": "string"
                      },
                      "dateOfService": {
                        "type": "string",
                        "format": "date"
                      },
                      "reasonForVisit": {
                        "type": "string"
                      },
                      "service": {
                        "type": "string"
                      },
                      "encounterType": {
                        "type": "string"
                      },
                      "providerId": {
                        "type": "string"
                      },
                      "billingCode": {
                        "type": "array",
                        "items": {
                          "type": "object",
                          "properties": {
                            "id": { "type": "string" },
                            "name": { "type": "string" },
                            "code": { "type": "string" },
                            "diagnosisCode": { "type": "array", "items": { "type": "string" }},
                            "billAmount": { "type": "number" },
                            "modifierA": { "type": "string" },
                            "modifierB": { "type": "string" }
                          }
                        }
                      },
                      "selectedProviders": {
                        "type": "object",
                        "properties": {
                          "organizationName": { "type": "string" },
                          "npi": { "type": "string" },
                          "firstName": { "type": "string" },
                          "lastName": { "type": "string" },
                          "address1": { "type": "string" },
                          "address2": { "type": "string" },
                          "city": { "type": "string" },
                          "state": { "type": "string" },
                          "zip": { "type": "string" }
                        }
                      }
                    },
                    "required": [
                      "diagnosisCode",
                      "dateOfService",
                      "reasonForVisit",
                      "service",
                      "encounterType",
                      "providerId",
                      "billingCode",
                      "selectedProviders"
                    ]
                  },
                  "callbackInfo": {
                    "type": "array",
                    "items": {
                      "type": "object",
                      "properties": {
                        "id": { "type": "string" },
                        "UserID": { "type": "string" },
                        "Name": { "type": "string" },
                        "URL": { "type": "string" },
                        "Auth": { "type": "string" },
                        "Event": { "type": "string" },
                        "messageMappings": { "type": "object" },
                        "payerMappings": { "type": "object" }
                      }
                    }
                  }
                },
                "required": [
                  "uid",
                  "patientId",
                  "encounterData",
                  "callbackInfo"
                ]
              },
              "example": {
                "uid": "12345",
                "patientId": "67890",
                "encounterData": {
                  "diagnosisCode": "A001",
                  "dateOfService": "2023-01-01",
                  "reasonForVisit": "Routine Checkup",
                  "service": "Outpatient",
                  "encounterType": "Initial",
                  "providerId": "provider123",
                  "billingCode": [
                    {
                      "id": "code1",
                      "name": "Test Code",
                      "code": "001",
                      "diagnosisCode": ["D001"],
                      "billAmount": 100,
                      "modifierA": "A",
                      "modifierB": "B"
                    }
                  ],
                  "selectedProviders": {
                    "organizationName": "Health Org",
                    "npi": "1234567890",
                    "firstName": "John",
                    "lastName": "Doe",
                    "address1": "123 Main St",
                    "address2": "Apt 4",
                    "city": "Anytown",
                    "state": "CA",
                    "zip": "12345"
                  }
                },
                "callbackInfo": [
                  {
                    "id": "callback1",
                    "UserID": "12345",
                    "Name": "Test Callback",
                    "URL": "https://example.com/api",
                    "Auth": "Bearer token",
                    "Event": "Encounters",
                    "messageMappings": {
                      "default": {
                        "field1": "encounterData.field1",
                        "field2": "encounterData.field2"
                      }
                    },
                    "payerMappings": {
                      "payers": {
                        "payer1": "payerCode1",
                        "payer2": "payerCode2"
                      }
                    }
                  }
                ]
              }
            }
          },
          "responses": {
            "200": {
              "description": "Data successfully processed and forwarded.",
              "content": {
                "application/json": {
                  "schema": {
                    "type": "object",
                    "properties": {
                      "message": {
                        "type": "string"
                      }
                    }
                  }
                }
              }
            },
            "400": {
              "description": "Bad request, missing or incorrect data."
            },
            "500": {
              "description": "Internal server error."
            }
          }
        }
      }
    },
    "components": {
      "securitySchemes": {
        "ApiKeyAuth": {
          "type": "apiKey",
          "in": "header",
          "name": "Authorization"
        }
      }
    },
    "security": [
      {
        "ApiKeyAuth": []
      }
    ]
  }}