import React, { useState, useEffect } from 'react';
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import useUID from '../General/useUID';

function ClinicNameFetcher({ clinicToken }) {
  const [clinicName, setClinicName] = useState(null); // Initialize with null to handle reset
  const [uid] = useUID();

  useEffect(() => {
    if (!uid) return;
    const fetchClinicName = async () => {
      try {

          if (clinicToken) {
            const clinicDocRef = doc(db, "clinics", clinicToken);
            const clinicDoc = await getDoc(clinicDocRef);
            if (clinicDoc.exists()) {
              setClinicName(clinicDoc.data().clinicName);
            } else {
              setClinicName(null); // Reset if no clinic found
            }
          } else {
            setClinicName(null); // Reset if no clinic token
          }
     
      } catch (error) {
        setClinicName(null); // Reset in case of error
        console.error("Error fetching clinic name:", error);
      }
    };

    fetchClinicName();
  }, [uid]);

  return (
    clinicName && (
      <p>
        <span style={{ opacity: 0.75 }}>Clinic Name: </span> <span>{clinicName}</span>
      </p>
    )
  );
}

export default ClinicNameFetcher;
